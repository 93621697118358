import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import { useState, useEffect } from 'react'; 
import { Button, Divider, Dropdown, Grid, Header, Input, Modal } from "semantic-ui-react";
import InternalPage from "../framework/internal_page";
import ReeLaAPI from "../util/reela_lib";

import RSettings from "../util/funcs/settings"

// import config
import { config } from '../config';

const Settings = (props:any) => {
  const {t, i18n} = useTranslation();
  const navigate = useNavigate();

  const [settings, setSettings] = useState<any>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [modal, setModal] = useState<any>();

  useEffect( () => {
    getSettings();
  }, []);

  const getSettings = async () => {
    if(isLoading) return null
    setIsLoading(true)

    var reelaApi:any = new ReeLaAPI;
    reelaApi.baseAPI = localStorage.getItem('domain_target');
    reelaApi.authToken = localStorage.getItem('auth_token');

    var res:any = await reelaApi.getSettings();

    if(!res) {
      if(process.env.NODE_MODE === 'development') console.error('Error with the response');

      setModal({
        visible: true,
        title: t('g.failedToProcessRequest'),
        message: t('g.failedToProcessRequestMessage'),
        action: [
          {
            content: t('g.tryAgain'),
            onClick: () => getSettings()
          },
          {
            content: t('g.cancel'),
            onClick: () => null
          },
        ]
      });
    }

    else if(res.status === "fail"){
      setModal({
        visible: true,
        title: t('g.failedToProcessRequest'),
        message: reelaApi.getErrorsString(),
        action: [
          {
            content: t('g.tryAgain'),
            onClick: () => getSettings()
          },
          {
            content: t('g.cancel'),
            onClick: () => null
          },
        ]
      });
    } 

    if(res.status === "success" && res.data) {
      setSettings(res.data);
    }

    setIsLoading(false)
    return null;
  }

  const updateSettings = async () => { 
    var reelaApi:any = new ReeLaAPI;
    reelaApi.baseAPI = localStorage.getItem('domain_target');
    reelaApi.authToken = localStorage.getItem('auth_token');
    reelaApi.keys = settings;

    var res:any = await reelaApi.updateSettings();

    if(res && res.status === 'success') {
      setModal({
        visible: true,
        title: 'Process completed',
        message: 'Process completed successfully',
        actions: [{ content: t('g.done'), positive: true }]
      });

      return null;
    }
    
    setModal({
      visible: true,
      title: t("g.failedToProcessRequest"),
      message: t("g.failedToProcessRequestMessage"),
      actions: [{ key: 0, content: t("g.ok") }]
    });
    return null;
  }

  const setSettingValue = (settingUuid:string, value:string|number) => { 
    var tempSettingObj = [];

    for (var i = 0; i < settings.length; i++){
      if (settings[i].uuid === settingUuid) {

        settings[i].value = value

      }
      tempSettingObj.push(settings[i])
    }

    setSettings(tempSettingObj);
  }

  return <InternalPage>
    <Modal
      size='mini'
      onClose={() => setModal(null)}
      open={(modal && modal.visible) ? true : false}
      header={modal ? modal.title : null}
      content={modal ? modal.message : null}
      actions={modal ? modal.actions : ['Close']}
    />
    
    <Header as='h1'>
      System Settings
      <Header.Subheader>Edit and update system settings</Header.Subheader>
    </Header>

    <Button
      icon='globe'
      content="Service Cities"
      onClick={() => navigate('./service-cities')}
    />

    <Button
      icon='mobile alternate'
      content="OTP"
      onClick={() => navigate('../system/otp/listing')}
    />

    <Divider />

    <Header as='h2'>Payment Methods</Header>

    <Grid columns={2} divided>
      <Grid.Row>
        <Grid.Column>
          <div>
            <strong>
              {RSettings.getSettingInfo('apple-pay-enabled', settings)?.title}
            </strong>
          </div>
          <p>
            {RSettings.getSettingInfo('apple-pay-enabled', settings)?.description}
          </p>
        </Grid.Column>
        <Grid.Column>
          <Dropdown
            options={[
              { key: 0, text: "Yes", value: "true" },
              { key: 1, text: "No", value: "false" },
            ]}
            selection
            value={RSettings.getSettingInfo('apple-pay-enabled', settings)?.value === 'true' ? 'true' : 'false'||''}
            onChange={(e, {value}) => {
              if (typeof value === 'string') {
                setSettingValue('apple-pay-enabled', value)
              }
            }}
            fluid
          />
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column>
          <div>
            <strong>
              {RSettings.getSettingInfo('cash-enabled', settings)?.title}
            </strong>
          </div>
          <p>
            {RSettings.getSettingInfo('cash-enabled', settings)?.description}
          </p>
        </Grid.Column>
        <Grid.Column>
          <Dropdown
            options={[
              { key: 0, text: "Yes", value: "true" },
              { key: 1, text: "No", value: "false" },
            ]}
            selection
            value={RSettings.getSettingInfo('cash-enabled', settings)?.value === 'true' ? 'true' : 'false'||''}
            onChange={(e, {value}) => {
              if (typeof value === 'string') {
                setSettingValue('cash-enabled', value)
              }
            }}
            fluid
          />
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column>
          <div>
            <strong>
              {RSettings.getSettingInfo('credit-card-enabled', settings)?.title}
            </strong>
          </div>
          <p>
            {RSettings.getSettingInfo('credit-card-enabled', settings)?.description}
          </p>
        </Grid.Column>
        <Grid.Column>
          <Dropdown
             options={[
              { key: 0, text: "Yes", value: "true" },
              { key: 1, text: "No", value: "false" },
            ]}
            selection
            value={RSettings.getSettingInfo('credit-card-enabled', settings)?.value === 'true' ? 'true' : 'false'||''}
            onChange={(e, {value}) => {
              if (typeof value === 'string') {
                setSettingValue('credit-card-enabled', value)
              }
            }}
            fluid
          />
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column>
          <div>
            <strong>
              {RSettings.getSettingInfo('bank-enabled', settings)?.title}
            </strong>
          </div>
          <p>
            {RSettings.getSettingInfo('bank-enabled', settings)?.description}
          </p>
        </Grid.Column>
        <Grid.Column>
          <Dropdown
             options={[
              { key: 0, text: "Yes", value: "true" },
              { key: 1, text: "No", value: "false" },
            ]}
            selection
            value={RSettings.getSettingInfo('bank-enabled', settings)?.value === 'true' ? 'true' : 'false'||''}
            onChange={(e, {value}) => {
              if (typeof value === 'string') {
                setSettingValue('bank-enabled', value)
              }
            }}
            fluid
          />
        </Grid.Column>
      </Grid.Row>

      <Header as='h2'>URLs</Header>

      <Grid.Row>
        <Grid.Column>
          <div>
            <strong>
              {RSettings.getSettingInfo('contact-url', settings)?.title}
            </strong>
          </div>
          <p>
            {RSettings.getSettingInfo('contact-url', settings)?.description}
          </p>
        </Grid.Column>
        <Grid.Column>
          <Input
            placeholder=''
            value={RSettings.getSettingInfo('contact-url', settings)?.value||''}
            onChange={(e, {value}) => setSettingValue('contact-url', value)}
            icon={'world'}
            fluid
          />
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column>
          <div>
            <strong>
              {RSettings.getSettingInfo('help-url', settings)?.title}
            </strong>
          </div>
          <p>
            {RSettings.getSettingInfo('help-url', settings)?.description}
          </p>
        </Grid.Column>
        <Grid.Column>
          <Input
            placeholder=''
            value={RSettings.getSettingInfo('help-url', settings)?.value||''}
            onChange={(e, {value}) => setSettingValue('help-url', value)}
            icon={'world'}
            fluid
          />
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column>
          <div>
            <strong>
              {RSettings.getSettingInfo('sign-up-url', settings)?.title}
            </strong>
          </div>
          <p>
            {RSettings.getSettingInfo('sign-up-url', settings)?.description}
          </p>
        </Grid.Column>
        <Grid.Column>
          <Input
            placeholder=''
            value={RSettings.getSettingInfo('sign-up-url', settings)?.value||''}
            onChange={(e, {value}) => setSettingValue('sign-up-url', value)}
            icon={'world'}
            fluid
          />
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column>
          <div>
            <strong>
              {RSettings.getSettingInfo('tac-url', settings)?.title}
            </strong>
          </div>
          <p>
            {RSettings.getSettingInfo('tac-url', settings)?.description}
          </p>
        </Grid.Column>
        <Grid.Column>
          <Input
            placeholder=''
            value={RSettings.getSettingInfo('tac-url', settings)?.value||''}
            onChange={(e, {value}) => setSettingValue('tac-url', value)}
            icon={'world'}
            fluid
          />
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column>
          <div>
            <strong>
              {RSettings.getSettingInfo('web-app-url', settings)?.title}
            </strong>
          </div>
          <p>
            {RSettings.getSettingInfo('web-app-url', settings)?.description}
          </p>
        </Grid.Column>
        <Grid.Column>
          <Input
            placeholder=''
            value={RSettings.getSettingInfo('web-app-url', settings)?.value||''}
            onChange={(e, {value}) => setSettingValue('web-app-url', value)}
            icon={'world'}
            fluid
          />
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column>
          <div>
            <strong>
              {RSettings.getSettingInfo('website-url', settings)?.title}
            </strong>
          </div>
          <p>
            {RSettings.getSettingInfo('website-url', settings)?.description}
          </p>
        </Grid.Column>
        <Grid.Column>
          <Input
            placeholder=''
            value={RSettings.getSettingInfo('website-url', settings)?.value||''}
            onChange={(e, {value}) => setSettingValue('website-url', value)}
            icon={'world'}
            fluid
          />
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column>
          <div>
            <strong>
              {RSettings.getSettingInfo('bank-information-url', settings)?.title}
            </strong>
          </div>
          <p>
            {RSettings.getSettingInfo('bank-information-url', settings)?.description}
          </p>
        </Grid.Column>
        <Grid.Column>
          <Input
            placeholder=''
            value={RSettings.getSettingInfo('bank-information-url', settings)?.value||''}
            onChange={(e, {value}) => setSettingValue('bank-information-url', value)}
            icon={'world'}
            fluid
          />
        </Grid.Column>
      </Grid.Row>


      <Header as='h2'>Address</Header>

      <Grid.Row>
        <Grid.Column>
          <div>
            <strong>
              {RSettings.getSettingInfo('system-country', settings)?.title}
            </strong>
          </div>
          <p>
            {RSettings.getSettingInfo('system-country', settings)?.description}
          </p>
        </Grid.Column>
        <Grid.Column>
          <Input
            placeholder=''
            value={RSettings.getSettingInfo('system-country', settings)?.value||''}
            onChange={(e, {value}) => setSettingValue('system-country', value)}
            fluid
          />
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column>
          <div>
            <strong>
              {RSettings.getSettingInfo('system-state', settings)?.title}
            </strong>
          </div>
          <p>
            {RSettings.getSettingInfo('system-state', settings)?.description}
          </p>
        </Grid.Column>
        <Grid.Column>
          <Input
            placeholder=''
            value={RSettings.getSettingInfo('system-state', settings)?.value||''}
            onChange={(e, {value}) => setSettingValue('system-state', value)}
            fluid
          />
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column>
          <div>
            <strong>
              {RSettings.getSettingInfo('system-city', settings)?.title}
            </strong>
          </div>
          <p>
            {RSettings.getSettingInfo('system-city', settings)?.description}
          </p>
        </Grid.Column>
        <Grid.Column>
          <Input
            placeholder=''
            value={RSettings.getSettingInfo('system-city', settings)?.value||''}
            onChange={(e, {value}) => setSettingValue('system-city', value)}
            fluid
          />
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column>
          <div>
            <strong>
              {RSettings.getSettingInfo('system-address-1', settings)?.title}
            </strong>
          </div>
          <p>
            {RSettings.getSettingInfo('system-address-1', settings)?.description}
          </p>
        </Grid.Column>
        <Grid.Column>
          <Input
            placeholder=''
            value={RSettings.getSettingInfo('system-address-1', settings)?.value||''}
            onChange={(e, {value}) => setSettingValue('system-address-1', value)}
            fluid
          />
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column>
          <div>
            <strong>
              {RSettings.getSettingInfo('system-address-2', settings)?.title}
            </strong>
          </div>
          <p>
            {RSettings.getSettingInfo('system-address-2', settings)?.description}
          </p>
        </Grid.Column>
        <Grid.Column>
          <Input
            placeholder=''
            value={RSettings.getSettingInfo('system-address-2', settings)?.value||''}
            onChange={(e, {value}) => setSettingValue('system-address-2', value)}
            fluid
          />
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column>
          <div>
            <strong>
              {RSettings.getSettingInfo('system-zip-code', settings)?.title}
            </strong>
          </div>
          <p>
            {RSettings.getSettingInfo('system-zip-code', settings)?.description}
          </p>
        </Grid.Column>
        <Grid.Column>
          <Input
            placeholder=''
            value={RSettings.getSettingInfo('system-zip-code', settings)?.value||''}
            onChange={(e, {value}) => setSettingValue('system-zip-code', value)}
            fluid
          />
        </Grid.Column>
      </Grid.Row>

      <Header as='h2'> Contact information</Header>

      <Grid.Row>
        <Grid.Column>
          <div>
            <strong>
              {RSettings.getSettingInfo('system-email', settings)?.title}
            </strong>
          </div>
          <p>
            {RSettings.getSettingInfo('system-email', settings)?.description}
          </p>
        </Grid.Column>
        <Grid.Column>
          <Input
            placeholder=''
            value={RSettings.getSettingInfo('system-email', settings)?.value||''}
            onChange={(e, {value}) => setSettingValue('system-email', value)}
            fluid
          />
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column>
          <div>
            <strong>
              {RSettings.getSettingInfo('system-phone', settings)?.title}
            </strong>
          </div>
          <p>
            {RSettings.getSettingInfo('system-phone', settings)?.description}
          </p>
        </Grid.Column>
        <Grid.Column>
          <Input
            placeholder=''
            value={RSettings.getSettingInfo('system-phone', settings)?.value||''}
            onChange={(e, {value}) => setSettingValue('system-phone', value)}
            fluid
          />
        </Grid.Column>
      </Grid.Row>

      <Header as='h2'> System information</Header>

      <Grid.Row>
        <Grid.Column>
          <div>
            <strong>
              {RSettings.getSettingInfo('system-name', settings)?.title}
            </strong>
          </div>
          <p>
            {RSettings.getSettingInfo('system-name', settings)?.description}
          </p>
        </Grid.Column>
        <Grid.Column>
          <Input
            placeholder=''
            value={RSettings.getSettingInfo('system-name', settings)?.value||''}
            onChange={(e, {value}) => setSettingValue('system-name', value)}
            fluid
          />
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column>
          <div>
            <strong>
              {RSettings.getSettingInfo('system-legal-name', settings)?.title}
            </strong>
          </div>
          <p>
            {RSettings.getSettingInfo('system-legal-name', settings)?.description}
          </p>
        </Grid.Column>
        <Grid.Column>
          <Input
            placeholder=''
            value={RSettings.getSettingInfo('system-legal-name', settings)?.value||''}
            onChange={(e, {value}) => setSettingValue('system-legal-name', value)}
            fluid
          />
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column>
          <div>
            <strong>
              {RSettings.getSettingInfo('system-license-number', settings)?.title}
            </strong>
          </div>
          <p>
            {RSettings.getSettingInfo('system-license-number', settings)?.description}
          </p>
        </Grid.Column>
        <Grid.Column>
          <Input
            placeholder=''
            value={RSettings.getSettingInfo('system-license-number', settings)?.value||''}
            onChange={(e, {value}) => setSettingValue('system-license-number', value)}
            fluid
          />
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column>
          <div>
            <strong>
              {RSettings.getSettingInfo('tax-id', settings)?.title}
            </strong>
          </div>
          <p>
            {RSettings.getSettingInfo('tax-id', settings)?.description}
          </p>
        </Grid.Column>
        <Grid.Column>
          <Input
            placeholder=''
            value={RSettings.getSettingInfo('tax-id', settings)?.value||''}
            onChange={(e, {value}) => setSettingValue('tax-id', value)}
            fluid
          />
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column>
          <div>
            <strong>
              {RSettings.getSettingInfo('shipping-cost', settings)?.title}
            </strong>
          </div>
          <p>
            {RSettings.getSettingInfo('shipping-cost', settings)?.description}
          </p>
        </Grid.Column>
        <Grid.Column>
          <Input
            placeholder=''
            value={RSettings.getSettingInfo('shipping-cost', settings)?.value||''}
            onChange={(e, {value}) => setSettingValue('shipping-cost', value)}
            label={t(`c.${localStorage.getItem('currency-key')}`)}
            labelPosition='right'
            fluid
          />
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column>
          <div>
            <strong>
              {RSettings.getSettingInfo('pricing-module', settings)?.title}
            </strong>
          </div>
          <p>
            {RSettings.getSettingInfo('pricing-module', settings)?.description}
          </p>
        </Grid.Column>
        <Grid.Column>
          <Dropdown
            options={[
              { key: 0, text: "Mockup", value: "mockup" },
              { key: 1, text: "Direct", value: "direct" },
            ]}
            selection
            value={RSettings.getSettingInfo('pricing-module', settings)?.value === 'mockup' ? 'mockup' : 'direct'||''}
            onChange={(e, {value}) => {
              if (typeof value === 'string') {
                setSettingValue('pricing-module', value)
              }
            }}
            fluid
          />
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column>
          <div>
            <strong>
              {RSettings.getSettingInfo('service-fee-minimum-charge', settings)?.title}
            </strong>
          </div>
          <p>
            {RSettings.getSettingInfo('service-fee-minimum-charge', settings)?.description}
          </p>
        </Grid.Column>
        <Grid.Column>
          <Input
            placeholder=''
            value={RSettings.getSettingInfo('service-fee-minimum-charge', settings)?.value||''}
            onChange={(e, {value}) => setSettingValue('service-fee-minimum-charge', value)}
            label={t(`c.${localStorage.getItem('currency-key')}`)}
            labelPosition='right'
            fluid
          />
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column>
          <div>
            <strong>
              {RSettings.getSettingInfo('service-fee-percentage', settings)?.title}
            </strong>
          </div>
          <p>
            {RSettings.getSettingInfo('service-fee-percentage', settings)?.description}
          </p>
        </Grid.Column>
        <Grid.Column>
          <Input
            placeholder=''
            value={(RSettings.getSettingInfo('service-fee-percentage', settings)?.value * 100)||''}
            onChange={(e, {value}) => {
              if(value) {
                value = (Number(value) / 100).toFixed(2);
                setSettingValue('service-fee-percentage', value)
              }
              else setSettingValue('service-fee-percentage', '')
            }}
            label={'%'}
            labelPosition='right'
            fluid
          />
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column>
          <div>
            <strong>
              {RSettings.getSettingInfo('minimum-sub-for-free-shipping', settings)?.title}
            </strong>
          </div>
          <p>
            {RSettings.getSettingInfo('minimum-sub-for-free-shipping', settings)?.description}
          </p>
        </Grid.Column>
        <Grid.Column>
          <Input
            placeholder=''
            value={RSettings.getSettingInfo('minimum-sub-for-free-shipping', settings)?.value||''}
            onChange={(e, {value}) => setSettingValue('minimum-sub-for-free-shipping', value)}
            label={t(`c.${localStorage.getItem('currency-key')}`)}
            labelPosition='right'
            fluid
          />
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column>
          <div>
            <strong>
              {RSettings.getSettingInfo('minimum-sub-for-order', settings)?.title}
            </strong>
          </div>
          <p>
            {RSettings.getSettingInfo('minimum-sub-for-order', settings)?.description}
          </p>
        </Grid.Column>
        <Grid.Column>
          <Input
            placeholder=''
            value={RSettings.getSettingInfo('minimum-sub-for-order', settings)?.value||''}
            onChange={(e, {value}) => setSettingValue('minimum-sub-for-order', value)}
            label={t(`c.${localStorage.getItem('currency-key')}`)}
            labelPosition='right'
            fluid
          />
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column>
          <div>
            <strong>
              {RSettings.getSettingInfo('tax-value', settings)?.title}
            </strong>
          </div>
          <p>
            {RSettings.getSettingInfo('tax-value', settings)?.description}
          </p>
        </Grid.Column>
        <Grid.Column>
          <Input
            placeholder=''
            value={(RSettings.getSettingInfo('tax-value', settings)?.value * 100)||''}
            onChange={(e, {value}) => {
              if(value) {
                value = (Number(value) / 100).toFixed(2);
                setSettingValue('tax-value', value)
              }
              else setSettingValue('tax-value', '')
            }}
            label={'%'}
            labelPosition='right'
            fluid
          />
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column>
          <div>
            <strong>
              {RSettings.getSettingInfo('vendors-default-commission', settings)?.title}
            </strong>
          </div>
          <p>
            {RSettings.getSettingInfo('vendors-default-commission', settings)?.description}
          </p>
        </Grid.Column>
        <Grid.Column>
          <Input
            placeholder=''
            value={(RSettings.getSettingInfo('vendors-default-commission', settings)?.value * 100)||''}
            onChange={(e, {value}) => {
              if(value) {
                value = (Number(value) / 100).toFixed(2);
                setSettingValue('vendors-default-commission', value)
              }
              else setSettingValue('vendors-default-commission', '')
            }}
            label={'%'}
            labelPosition='right'
            fluid
          />
        </Grid.Column>
      </Grid.Row>
    </Grid>

    <Divider />
    
    <Button
      primary
      icon='save'
      content={t("g.save")}
      onClick={() => updateSettings()}
    />
  </InternalPage>;
}

export default Settings;
