import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next"
import { useNavigate, useParams } from "react-router";
import { Button, Divider, Form, FormSelect, Header, Message, Modal } from "semantic-ui-react";
import { config } from "../config";
import InternalPage from "../framework/internal_page";
import Map from "../framework/map";
import Unauthorized from "../framework/unauthorized";
import ReeLaAPI from "../util/reela_lib";

const CustomerForm = (props:any) => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();

  interface customerType {
    registrar_id: number|string|null,
    country_code: number|string|null,
    phone: number|string|null,
    tax_id: number|string|null,
    license_number: number|string|null,
    location: {
      latitude: string|number|null,
      longitude: string|number|null
    }|null,
    name_local: {
      en: string|null,
      [key: string]: string|null
    },
    service_city_id: any,
    address_1: string|null,
    password: string|null
  }

  const [customer, setCustomer] = useState<customerType>({
    registrar_id: null,
    phone: null,
    country_code: null,
    location: null,
    license_number: null,
    tax_id: null,
    name_local: {
      en: null,
      ar: null
    },
    service_city_id: null,
    address_1: null,
    password: null
  });

  const [isLoadingServiceCities, setIsLoadingServiceCities] = useState<boolean>(false);
  const [serviceCities, setServiceCities] = useState<any>(null);

  // screen states
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isProcessing, setIsProcessing] = useState<boolean>(false);
  const [otpRequested, setOtpRequested] = useState<boolean>(false);

  // form inputs
  const [otp, setOtp] = useState<string|null>(null);
  
  const [modal, setModal] = useState<any>();

  // set lets
  let profile:any = null;
  let management:boolean = false;
  let registrar:boolean = false;

  try {
    profile = JSON.parse(localStorage.getItem('profile') || '');
    management = false;

    if(profile.type === 'management') management = true;

    // if the user is registrar -> set his ID
    if(profile.type === 'registrar' && !customer.registrar_id) {
      registrar = true;

      setCustomer({
        ...customer,
        registrar_id: profile.id
      });
    }
  }
  catch(e) {
    console.warn('Error parsing the profile', e);
  }

  useEffect(() => {
    getServiceCities();
  }, []);

  const getMyLocation = () => {
    if("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition((position) => {
        setCustomer({
          ...customer,
          location: {
            latitude: position.coords.latitude,
            longitude: position.coords.longitude
          }
        })
      });
    }
  }

  const getServiceCities = async () => {
    if(isLoadingServiceCities) return null;

    setIsLoadingServiceCities(true);

    var reelaApi:any = new ReeLaAPI; 
    reelaApi.baseAPI = localStorage.getItem('domain_target');
    reelaApi.authToken = localStorage.getItem('auth_token');
    
    var res:any = await reelaApi.getServiceCities();

    setIsLoadingServiceCities(false);

    if(process.env.NODE_MODE === 'development') console.log('getServiceCities response:', res);

    if(!res || res.status !== 'success') {
      setModal({
        visible: true,
        title: t("g.failedToProcessRequest"),
        message: reelaApi.getErrorsString(),
        actions: [
          { content: t('g.ok') }
        ]
      });

      return null;
    } 

    var organizedServiceCities:any = [];

    res.data.forEach((sc:any, key:number) => {
      var local = sc.local;

      if(local && typeof local === 'string') {
        try { local = JSON.parse(local) }
        catch(e) {
          if(process.env.NODE_MODE === 'development') {
            console.error('Failed to parse service city local', sc, e);
          }

          return null;
        }
      }
      
      if(local) organizedServiceCities.push({
        key: sc.id,
        text: `${local[i18n.language||'en'].city}, ${local[i18n.language||'en'].state}, ${local[i18n.language||'en'].country}`,
        value: sc.id
      })
    });

    setServiceCities(organizedServiceCities);
    return null;
  }

  const sendOtp = async () => {
    if(isProcessing) return null;

    setIsProcessing(true);

    var reelaApi:any = new ReeLaAPI;
    reelaApi.baseAPI = localStorage.getItem('domain_target');
    reelaApi.countryCode = customer.country_code;
    reelaApi.phone = customer.phone;

    var res = await reelaApi.sendOTP();
    setIsProcessing(false);

    if(!res || !res.code) {
      setModal({
        visible: true,
        title: t("g.failedToProcessRequest"),
        message: reelaApi.getErrorsString(),
        actions: [
          { content: t('g.ok') }
        ]
      });
      
      return null;
    }

    setModal({
      visible: true,
      title: t("g.processCompleted"),
      message: t("g.processCompletedMessage"),
      actions: [{ content: t('g.ok'), positive: true }]
    });

    setOtpRequested(true);

    return null;
  }

  const processCustomer = async () => {
    // > process customer
    if(isProcessing) return null;
    setIsProcessing(true);

    var reelaApi:any = new ReeLaAPI;
    reelaApi.baseAPI = localStorage.getItem('domain_target');
    reelaApi.authToken = localStorage.getItem('auth_token');
    reelaApi.otpCode = otp;
    reelaApi.password = customer.password;
    reelaApi.countryCode = customer.country_code;
    reelaApi.phone = customer.phone;
    reelaApi.nameLocal = JSON.stringify(customer.name_local);
    reelaApi.taxId = customer.tax_id;
    reelaApi.licenseNumber = customer.license_number;
    reelaApi.location = JSON.stringify(customer.location);
    reelaApi.serviceCityId = customer.service_city_id;
    reelaApi.address1 = customer.address_1;
    
    var res:any = await reelaApi.quickRegister();
    setIsProcessing(false);

    if(!res || res.status !== 'success') {
      if(process.env.NODE_MODE === 'development') console.warn('processCustomer error', res);

      setModal({
        visible: true,
        title: t("g.failedToProcessRequest"),
        message: reelaApi.getErrorsString(),
        actions: [{ content: t('g.ok') }]
      });
    }
    else {
      setModal({
        visible: true,
        title: t("g.processCompleted"),
        message: t("g.processCompletedMessage"),
        actions: [{ content: t('g.ok'), positive: true }]
      });

      navigate('/customers');
    }

    return null;
  }

  // check if user is authorized
  var allowedUsers = ['management', 'registrar'];

  if(!profile || !allowedUsers.includes(profile.type)) {
    return <InternalPage>
      <Unauthorized />
    </InternalPage>
  }

  return <InternalPage loading={isLoading}>
    <Modal
      size='mini'
      onClose={() => setModal(null)}
      open={(modal && modal.visible) ? true : false}
      header={modal ? modal.title : null}
      content={modal ? modal.message : null}
      actions={modal ? modal.actions : ['Close']}
    />

    <Header as='h1'>
      {t('s.customers.quickRegister')}
    </Header>

    <Form onSubmit={() => processCustomer()}>
      <Form.Field>
        <Form.Input
          required
          disabled
          label={t('s.registrars.registrarId')}
          placeholder={'Type here'}
          value={customer.registrar_id}
          onChange={(e, {value}) => {
            setCustomer({
              ...customer,
              registrar_id: value||null
            })
          }}
        />
      </Form.Field>
      <Form.Group widths={2}>
        <Form.Field>
          <FormSelect
            floating 
            selection
            label={t('s.profile.countryCode')}
            options={[
              {key: '+966', text: 'Saudi Arabia | +966', value: '+966'},
              {key: '+20', text: 'Egypt | +20', value: '+20'}
            ]} 
            defaultValue={customer.country_code||undefined}
            onChange={(e, { name, value }) => {
              if(value && typeof value === 'string') {
                setCustomer({
                  ...customer,
                  country_code: value||null
                });
              }
            }}
          />
        </Form.Field>
        <Form.Field>
          <Form.Input
            required
            label={t('g.phone')}
            value={customer.phone}
            onChange={(e, { value }) => {
              if(value[0] !== '0') setCustomer({
                ...customer,
                phone: value||null
              });
            }}
          />
        </Form.Field>
      </Form.Group>

      <Form.Field>
        <Form.Input
          required
          label={t('g.name')}
          placeholder={t("g.typeHere")}
          value={customer?.name_local?.en||''}
          onChange={(e, {value}) => setCustomer({
            ...customer,
            name_local: {
              en: value||null,
              ar: value||null
            }
          })}
        />
      </Form.Field>

      <Form.Field>
        <Form.Input
          required
          label={t('s.customers.taxId')}
          placeholder={t("g.typeHere")}
          value={customer.tax_id}
          onChange={(e, {value}) => setCustomer({
            ...customer,
            tax_id: value
          })}
        />
      </Form.Field>
      
      <Form.Field>
        <Form.Input
          required
          label={t('s.customers.licenseNumber')}
          placeholder={t("g.typeHere")}
          value={customer.license_number}
          onChange={(e, {value}) => setCustomer({
            ...customer,
            license_number: value
          })}
        />
      </Form.Field>

      {/** Map location with selection **/}
      <Divider hidden />

      <Button
        // icon='location'
        content={t('g.myCurrentLocation')}
        primary
        fluid
        type='button'
        onClick={(e)=>{ e.preventDefault(); getMyLocation() }}
      />

      <Divider hidden />
      
      <div style={{overflow: 'hidden', borderRadius: 10}}>
        <Map
          search
          height={500}
          center={(
            customer.location 
            && customer.location.latitude 
            && customer.location.longitude
          ) 
          ? {
            lat: customer.location.latitude, 
            lng: customer.location.longitude
          }
          : null }
          markers={(
            customer.location 
            && customer.location.latitude 
            && customer.location.longitude
          ) 
          ? [
              {
                lat: customer.location.latitude, 
                lng: customer.location.longitude
              }
            ]
          : null }
          options={{
            draggableCursor: "default",
            draggingCursor: "pointer",
            fullscreenControl: false,
          }}
          onClick={(ev:any) => {
            setCustomer({
              ...customer,
              location: {
                latitude: ev.lat,
                longitude: ev.lng
              }
            })
          }}
        />
      </div> 

      <Divider hidden />

      <Form.Field>
        <Form.Input
          required
          label={t('g.address1')}
          placeholder={t("g.typeHere")}
          value={customer.address_1||''}
          onChange={(e, {value}) => setCustomer({
            ...customer,
            address_1: value||null
          })}
        />
      </Form.Field>

      <Form.Field>
        <Form.Dropdown
          required
          label={t('s.serviceCities.serviceCity')}
          placeholder={t('s.serviceCities.selectServiceCity') || 'Select Service City'}
          fluid
          selection
          clearable
          options={serviceCities}
          value={customer.service_city_id||null}
          onChange={(e, { name, value }) => {
            setCustomer({
              ...customer,
              service_city_id: value
            })
          }}
        />
      </Form.Field>

      <Form.Field>
        <Form.Input
          required
          label={t('g.password')}
          placeholder={t("g.typeHere")}
          value={customer.password||''}
          type="password"
          onChange={(e, {value}) => setCustomer({
            ...customer,
            password: value||null
          })}
        />
      </Form.Field>

      <Form.Field>
        <Message
          color="yellow"
          header={t('s.auth.phoneOtp')}
          content={t('s.customers.phoneOtpVerificationDescription')}
        />

        <Form.Button
          fluid
          content={t('s.auth.requestOtp')}
          type='button'
          color='violet'
          onClick={() => sendOtp()}
        />

        {otpRequested &&
        <Form.Input
          required
          label={t('s.auth.otp')}
          placeholder={t("g.typeHere")}
          value={otp||''}
          onChange={(e, {value}) => {
            setOtp(value)
          }}
        /> }
      </Form.Field>

      <Divider hidden />

      <Form.Group widths={2}>
        <Form.Field>
          <Form.Button
            fluid
            content={t('g.cancel')}
            type='button'
            onClick={() => navigate(-1)}
          />
        </Form.Field>
        <Form.Field>
          <Form.Button
            disabled={!otpRequested}
            fluid
            primary
            content={t('g.save')}
          />
        </Form.Field>
      </Form.Group>
    </Form>
  </InternalPage>
}

export default CustomerForm;