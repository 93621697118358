import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Form, Header, Message, Modal, Segment } from "semantic-ui-react";
import ReeLaAPI from "../../util/reela_lib";

// import config
import { config } from '../../config';

const ServiceCityForm = (props:any) => {
  const {t, i18n} = useTranslation();

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [modal, setModal] = useState<any>(null);

  const [serviceCity, setServiceCity] = useState<any>({
    id: null,
    city: null,
    state: null,
    country: null,
    local: null
  });

  useEffect(() =>{
    // setup a dummpy option if it's not set
    if(props.serviceCity) {
      // merge default and received objects
      setServiceCity({
        ...serviceCity, 
        ...props.serviceCity,
        local: JSON.stringify(props.serviceCity.local)
      });
    }
  }, [props.serviceCity]);

  const processOption = async () => {
    if(isLoading) return null;

    setIsLoading(true);

    // check if any requirements
    var reelaApi:any = new ReeLaAPI;
    reelaApi.baseAPI = localStorage.getItem('domain_target');
    reelaApi.authToken = localStorage.getItem('auth_token');
    reelaApi.serviceCityId = serviceCity.id;
    reelaApi.city = serviceCity.city;
    reelaApi.state = serviceCity.state;
    reelaApi.country = serviceCity.country;
    reelaApi.local = JSON.parse(serviceCity.local);

    if(reelaApi.serviceCityId)
      var res:any = await reelaApi.updateServiceCity();

    else
      var res:any = await reelaApi.createServiceCity();

    setIsLoading(false);

    if(res && res.status === 'success') { 
      setModal({
        visible: true,
        title: t("g.processCompleted"),
        message: t("g.processCompletedMessage"),
        actions: [{ 
          content: t('g.done'), 
          positive: true,
          onClick: () => props.onSuccess()|| null
        }]
      }); 

      return null;
    }

    setModal({
      visible: true,
      title: t("g.failedToProcessRequest"),
      message: t("g.failedToProcessRequestMessage"),
      actions: [{ content: t("g.ok") }]
    })

    return null;
  }

  return <>
    <Message
      color='yellow'
      icon='info circle'
      header='IMPORTANT!'
      content='Please do not edit this section without proper 
      reading of the documentation related to this section.
      The documentation is available on the project core files
      under /docs/service-cities.md.'
    />
    
    <Segment>
      <Modal
        size='mini'
        onClose={() => setModal(null)}
        open={(modal && modal.visible) ? true : false}
        header={modal ? modal.title : null}
        content={modal ? modal.message : null}
        actions={modal ? modal.actions : ['Close']}
      />

      <Header as='h1'>
        {t("s.settings.serviceCities.title")}
      </Header>

      <Form onSubmit={processOption}>
        <Form.Field>
          <Form.Input
            fluid
            disabled
            label={`${t('g.id')}:`}
            value={serviceCity.id||''}
          />
        </Form.Field>
        <Form.Group widths={3}>
          <Form.Field>
            <Form.Input
              fluid
              required
              label='City:'
              value={serviceCity.city||''}
              onChange={(e, {value}) => setServiceCity({
                ...serviceCity,
                city: value
              })}
            />
          </Form.Field>
          <Form.Field>
            <Form.Input
              fluid
              required
              label='State:'
              value={serviceCity.state||''}
              onChange={(e, {value}) => setServiceCity({
                ...serviceCity,
                state: value
              })}
            />
          </Form.Field>
          <Form.Field>
            <Form.Input
              fluid
              required
              label='Country:'
              value={serviceCity.country||''}
              onChange={(e, {value}) => setServiceCity({
                ...serviceCity,
                country: value
              })}
            />
          </Form.Field>
        </Form.Group>
        <Form.Field>
          <Form.TextArea
            fluid
            required
            label='Local:'
            value={serviceCity.local||''}
            onChange={(e, {value}) => setServiceCity({
              ...serviceCity,
              local: value
            })}
          />
        </Form.Field>
        <Form.Group >
          <Form.Button
            content='Cancel'
            onClick={() => props.onClose()}
          />
          <Form.Button
            content='Save'
            primary
            type='submit'
          />
        </Form.Group>
      </Form>
    </Segment>
  </>
}

export default ServiceCityForm;