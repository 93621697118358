import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { 
  Grid, 
  Modal, 
  Header, 
  Button, 
  Divider, 
  Table,
  Message,
  Form,
  Container,
  TableHeader,
  Image
} from "semantic-ui-react";
import InternalPage from "../framework/internal_page";
import ReeLaAPI from "../util/reela_lib";

// import config
import { config } from '../config';
import { displayPrice, formatDate, URLQuery } from "../util/tools";
import Unauthorized from "../framework/unauthorized";
import OrderProductRow from "./item_row";

import RSettings from "../util/funcs/settings"
import BlankPage from "../framework/blank_page";
import QRCode from '../assets/qr-code.png';
import render_locals from "../util/funcs/render_locals";

const OrderInvoice = (props:any) => {
  const {t, i18n} = useTranslation();
  const navigate = useNavigate();

  let { orderUuid } = useParams<any>();

  // states
  const [isOrderLoading, setOrderLoading] = useState<boolean>(false);
  const [order, setOrder] = useState<any>(null);

  const [settings, setSettings] = useState<any>(false);
  const [isSettingsLoading, setIsStettingsLoading] = useState<boolean>(false);
  
  const [modal, setModal] = useState<any>();

  useEffect(() => {
    getOrder();
    getSettings();
  }, []);

  const getSettings = async () => {
    if(isSettingsLoading) return null
    setIsStettingsLoading(true)

    var reelaApi:any = new ReeLaAPI;
    reelaApi.baseAPI = localStorage.getItem('domain_target');
    reelaApi.authToken = localStorage.getItem('auth_token');

    var res:any = await reelaApi.getSettings();

    if(!res) {
      if(process.env.NODE_MODE === 'development') console.error('Error with the response');

      setModal({
        visible: true,
        title: t('g.failedToProcessRequest'),
        message: t('g.failedToProcessRequestMessage'),
        action: [
          {
            content: t('g.tryAgain'),
            onClick: () => getSettings()
          },
          {
            content: t('g.cancel'),
            onClick: () => null
          },
        ]
      });
    }

    else if(res.status === "fail"){
      setModal({
        visible: true,
        title: t('g.failedToProcessRequest'),
        message: reelaApi.getErrorsString(),
        action: [
          {
            content: t('g.tryAgain'),
            onClick: () => getSettings()
          },
          {
            content: t('g.cancel'),
            onClick: () => null
          },
        ]
      });
    } 

    if(res.status === "success" && res.data) {
      

      setSettings(res.data);
      
    }

    setIsStettingsLoading(false)
    return null;
  }
  
  const getOrder = async () => {
    setOrderLoading(true);

    var reelaApi:any = new ReeLaAPI;
    reelaApi.baseAPI = localStorage.getItem('domain_target');
    // reelaApi.authToken = localStorage.getItem('auth_token');
    reelaApi.orderUuid = orderUuid;

    var res:any = await reelaApi.getOrder();
    
    if(!res || !res.data) {
      setModal({
        visible: true,
        title: t("g.failedToProcessRequest"),
        message: reelaApi.getErrorsString(),
        actions: [
          { content: t('g.ok'), onClick: () => navigate(-1) }
        ]
      });

      setOrderLoading(false);
      return null;
    }

    var retrievedOrder = res.data;

    // set the order strings to objects where needed
    var customerDetails:any = retrievedOrder.order.customer_details;

    if(customerDetails && typeof customerDetails === 'string') {
      try {
        customerDetails = JSON.parse(customerDetails);
  
        retrievedOrder = {
          ...retrievedOrder,
          order: {
            ...retrievedOrder.order,
            customer_details: customerDetails
          }
        }
      }
      catch(e) {
        if(process.env.NODE_MODE === 'development') console.error(e);
      }
    }

    setOrder(retrievedOrder);
    setOrderLoading(false);
    return null;
  }

  // handle no order set
  if(!order) {
    return <BlankPage>
      <div>{t('g.noResults')}</div>
    </BlankPage>
  }

  return <BlankPage>
    <Grid padded>
      <Header as='h1'>
        {t("s.orders.invoice.title")}
        <Header.Subheader>#{orderUuid}</Header.Subheader>
      </Header>
    </Grid>
    <Divider hidden />

    <Grid padded>
      <Grid.Row columns={2}>
        <Grid.Column textAlign="left">
          <Header as='h3'>{t("s.orders.invoice.billTo")}</Header>
          <div>
            <div>
              <strong>{order.order.customer_details.name_local[localStorage.getItem('language') || 'en']}</strong>
            </div>
            <div>{order.order.customer_details.license_number}</div>
            <div>{order.order.customer_details.tax_id}</div>
            <div>
              <span>
                {order.order.customer_details.country_code}{order.order.customer_details.phone}
              </span>
              <span> - </span>
              <span>
                {order.order.customer_details.email}
              </span>
            </div>
            <br />
            <div>{order.order.customer_details.address_1}</div>
            <div>{order.order.customer_details.address_2}</div>
            <div>{order.order.customer_details.city}, {order.order.customer_details.state}, {order.order.customer_details.country}</div>
            <div>{order.order.customer_details.zip_code}</div>
          </div>
        </Grid.Column>
        <Grid.Column textAlign="right">
          <Header as='h3'>{t("s.orders.invoice.business")}</Header>
          <div>
            <div><strong>{t('g.appName')}</strong></div>
            <div>{RSettings.getSettingInfo('system-license-number', settings)?.value||''}</div>
            <div>{RSettings.getSettingInfo('tax-id', settings)?.value||''}</div>
            <div>{RSettings.getSettingInfo('system-phone', settings)?.value||''} - {RSettings.getSettingInfo('system-email', settings)?.value||''}</div>
            <br />
            <div>{RSettings.getSettingInfo('system-address-1', settings)?.value||''}</div>
            <div>{RSettings.getSettingInfo('system-address-2', settings)?.value||''}</div>
            <div>{RSettings.getSettingInfo('system-city', settings)?.value||''}, {RSettings.getSettingInfo('system-state', settings)?.value||''}, {RSettings.getSettingInfo('system-city', settings)?.value||''}</div>
            <div>{RSettings.getSettingInfo('system-zip-code', settings)?.value||''}</div>
          </div>
        </Grid.Column>
        <Grid.Column textAlign="right">

        </Grid.Column>
        <Grid.Column textAlign="right">
          <Divider hidden />
          <Header as='h3'>{t("g.registrar")}</Header>
          {order.order.registrar_id ? 
            <div>
              <div>{order.order.registrar_id || ""}</div>
              <div>{order.order.registrar_first_name || ""}  {order.order.registrar_last_name || ""}</div>
            </div>
            : 
            <div>
              {t('g.none')}
            </div> 
          }
          
        </Grid.Column>
      </Grid.Row>
    </Grid>

    <Divider hidden />

    <Grid padded>
      <Grid.Row columns={2}>
        <Grid.Column textAlign="left">
          <Header as='h3'>{t("s.orders.invoice.issueDate")}</Header>
          <div className="order-date">
            {formatDate(order.order.created_at)}
          </div>
        </Grid.Column>
        <Grid.Column textAlign="right">
          <Header as='h3'>{t("s.orders.invoice.dueDate")}</Header>
          <div>
            {formatDate(order.order.created_at)}
          </div>
        </Grid.Column>
      </Grid.Row>
    </Grid>

    <Divider hidden section />

    <Grid padded>
      <Table unstackable padded basic='very'>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>{t('g.id')}</Table.HeaderCell>
            <Table.HeaderCell>{t('s.products.product')}</Table.HeaderCell>
            <Table.HeaderCell textAlign='right'>{t('s.orders.price')}</Table.HeaderCell>
            <Table.HeaderCell textAlign='right'>{t('s.orders.tpu_full')}</Table.HeaderCell>
            <Table.HeaderCell textAlign='right'>{t('s.orders.pit_full')}</Table.HeaderCell>
            <Table.HeaderCell textAlign='right'>{t('s.orders.qty')}</Table.HeaderCell>
            <Table.HeaderCell textAlign='right'>{t('s.orders.total')}</Table.HeaderCell>
            <Table.HeaderCell textAlign='right'>{t('s.orders.tax')}</Table.HeaderCell>
            <Table.HeaderCell textAlign='right'>{t('s.orders.tit_full')}</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {order && order.items && order.items.map((item:any, key:number) => {
            var reelaApi = new ReeLaAPI;

            var itemName:any = item.name_local;
            itemName = render_locals.renderLocal(
              localStorage.getItem('language') || 'en',
              itemName
            );

            var vendorDetails:any = item.vendor_details;
            var vendorName:any = null;
            
            if(vendorDetails && typeof vendorDetails === 'string') {
              try {
                vendorDetails = JSON.parse(vendorDetails);
                vendorName = vendorDetails.name_local[i18n.language||'en'];
              }
              catch(e) {
                if(process.env.NODE_MODE === 'development') 
                  if(process.env.NODE_MODE === 'development') 
                if(process.env.NODE_MODE === 'development') 
                  console.error('Product name or description did not failed to parse', e);
              }
            }

            return <Table.Row>
              <Table.Cell width={1}>
                {key + 1}
              </Table.Cell>
              <Table.Cell width={3}>
                <div className="product-name">
                  <strong>{itemName}</strong>
                </div>
                <div className="vendor-name">
                  {vendorName}
                </div>
                <div>
                  {item.sku}
                </div>
              </Table.Cell>
              <Table.Cell width={1} textAlign='right'>
                {displayPrice(item.price)} {t(`c.${localStorage.getItem('currency-key')}`)}
              </Table.Cell>
              <Table.Cell width={2} textAlign='right'>
                {displayPrice(item.tax_per_unit)} {t(`c.${localStorage.getItem('currency-key')}`)}
              </Table.Cell>
              <Table.Cell width={2} textAlign='right'>
                {displayPrice(item.price_inc_tax)} {t(`c.${localStorage.getItem('currency-key')}`)}
              </Table.Cell>
              <Table.Cell width={1} textAlign='right'>
                {item.quantity}
              </Table.Cell>
              <Table.Cell width={2} textAlign='right'>
                {displayPrice(item.total)} {t(`c.${localStorage.getItem('currency-key')}`)}
              </Table.Cell>
              <Table.Cell width={2} textAlign='right'>
                {displayPrice(item.tax_total)} {t(`c.${localStorage.getItem('currency-key')}`)}
              </Table.Cell>
              <Table.Cell width={2} textAlign='right'>
                {displayPrice(item.total_inc_tax)} {t(`c.${localStorage.getItem('currency-key')}`)}
              </Table.Cell>
            </Table.Row>
          })}
        </Table.Body>
        <Table.Footer>
          <Table.Row>
            <Table.HeaderCell colSpan={8} textAlign='right'>{t('s.orders.subTotal')}</Table.HeaderCell>
            <Table.HeaderCell colSpan={2} textAlign='right'>
              {displayPrice(order.order.sub_total)} {t(`c.${localStorage.getItem('currency-key')}`)}
            </Table.HeaderCell>
          </Table.Row>
          <Table.Row>
            <Table.HeaderCell colSpan={8} textAlign='right'>{t('s.orders.shippingTotal')}</Table.HeaderCell>
            <Table.HeaderCell colSpan={2} textAlign='right'>
              {displayPrice(order.order.shipping_total)} {t(`c.${localStorage.getItem('currency-key')}`)}
            </Table.HeaderCell>
          </Table.Row>
          <Table.Row>
            <Table.HeaderCell colSpan={8} textAlign='right'>{t('s.orders.houseTax')}</Table.HeaderCell>
            <Table.HeaderCell colSpan={2} textAlign='right'>
              {displayPrice(order.order.house_tax_total)} {t(`c.${localStorage.getItem('currency-key')}`)}
            </Table.HeaderCell>
          </Table.Row>
          <Table.Row>
            <Table.HeaderCell colSpan={8} textAlign='right'>{t('s.orders.discount')}</Table.HeaderCell>
            <Table.HeaderCell colSpan={2} textAlign='right'>
              {displayPrice(order.order.discount)} {t(`c.${localStorage.getItem('currency-key')}`)}
            </Table.HeaderCell>
          </Table.Row>
          <Table.Row>
            <Table.HeaderCell colSpan={8} textAlign='right'>
              <Header as="h3">{t('s.orders.grandTotal')}</Header>
            </Table.HeaderCell>
            <Table.HeaderCell colSpan={2} textAlign='right'>
              <Header as="h3">{displayPrice(order.order.grand_total)} {t(`c.${localStorage.getItem('currency-key')}`)}</Header>
            </Table.HeaderCell>
          </Table.Row>
        </Table.Footer>
      </Table>
    </Grid>

    <Divider hidden section />

    <Grid>
      <Grid.Row columns={2}>
        <Grid.Column>
          <Header as='h1'>{t("g.thankYou")}</Header>
          <div>{t("s.orders.invoice.footerNotes")}</div>
        </Grid.Column>
        <Grid.Column >
          <Image 
            floated={'right'}
            src={QRCode} 
            width={150}
            height={150}
          />
        </Grid.Column>
      </Grid.Row>
    </Grid>

  </BlankPage>
}

export default OrderInvoice;