import { useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";
import { Form, Button } from 'semantic-ui-react';
  
// translation
import { useTranslation } from "react-i18next";
import i18next from "../services/i18n";

const LanguageSelection = (props:any) => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const [language, setLanguage] = useState<any>(null);

  useEffect( () => {
    if (!language){
      setLanguage({type: localStorage.getItem('language')});
      return;
    }

    localStorage.setItem('language', language.type);

    const currentLanguage = localStorage.getItem('language');
    i18next.init({lng: currentLanguage ? currentLanguage : language.type})
  }, [language]);

  return (
    <>
      <Form.Field>
        <Form.Dropdown
          required
          fluid
          closeOnEscape
          selection
          defaultValue={localStorage.getItem('language') || 'en' }
          onChange={(e, { name, value }) => {
            setLanguage({
                ...language,
                type: value
              })
              localStorage.setItem('language', language?.type)
          }}
          options={[
            { key: 1, text: t('l.english'), value: 'en' },
            { key: 2, text: t('l.arabic'), value: 'ar' },
            { key: 2, text: t('l.urdu'), value: 'ur' },
            { key: 2, text: t('l.hindi'), value: 'hi' }
          ]}
        />
      </Form.Field>
    </>
  )
}

export default LanguageSelection;