/*
 *
 * PROJECT ReeLa 
 * Developed by:  3WebBox LLC - 2022
 * 
 * Disclaimor: Please make sure to read related documentation before
 * making any changes to the code. Modify the code under your own
 * responsibility. for help please contact 3WebBox.
 * 
 * https://3webbox.com  : support@3webbox.com
 * 
 * 
 */

export default {
  getSettingInfo: (key, settingsList) => {
    
    if (settingsList.length < 1) {
      return null;
    }

    for(var i = 0; i < settingsList.length; i++ ) {

      if (settingsList[i].uuid === key){

        return settingsList[i];
      }
    }

    return null;
  }
}