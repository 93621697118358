import { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router'

import { 
  Form, 
  Grid, 
  Header, 
  Button,
  Modal,
  Input,
  Dropdown
} from 'semantic-ui-react';

// import SVG
import Logo from '../assets/logo-icon.svg';

// import jmla_lib
import ReeLaAPI from '../util/reela_lib';

// import app config
import { config } from '../config';
import { URLQuery } from '../util/tools';

// translation
import { useTranslation } from "react-i18next";

import SiteHeader from '../framework/site_header';

export default function Login() {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();

  // states
  interface modalType {
    visible: boolean,
    title: string,
    message: string,
    actions?: any[]
  }

  const [modal, setModal] = useState<modalType|null>(null);
  const [processing, setProcessing] = useState<boolean>(false);

  // login information states
  const [countryCode, setCountryCode] = useState<string>('+966');
  const [phone, setPhone] = useState<string|null>(null);
  const [password, setPassword] = useState<string|null>(null);

  const [domain, setDomain] = useState<string|undefined>('966');

  const [errors, setErrors] = useState<any[]>([]);

  useEffect(() => {
    if (
      localStorage.getItem('auth_token')
    ) {
      navigate('/')
    }

    
    if(!domain) {
      var urlDomain = URLQuery('domain', window);
      setDomain(urlDomain);
    }
  }, []);

  // set the domain then attempt the login
  const processDomain = async () => {
    if(processing) return false;
    setProcessing(true);

    var res:any = undefined;

    if(process.env.REACT_APP_FORCED_CORE_URI) {
      // this sectino will simulate a success response
      res = {
        status: 'success',
        data: {
          target: process.env.REACT_APP_FORCED_CORE_URI
        }
      }
    }
    else {
      var reelaApi:any = new ReeLaAPI();
      reelaApi.debug = process.env.REACT_APP_MODE === 'development' ? true : false;
      reelaApi.lang = 'en';
      reelaApi.baseAPI = process.env.REACT_APP_CORE_DISPATCH_URI;
      reelaApi.domain = domain;
  
      res =  await reelaApi.getDomain();
    }

    if(!res) {
      setErrors([{
        en: 'Failed to connect to the server'
      }]);

      setProcessing(false);
    }

    else if(res.status !== 'success') {
      setErrors(res.errors);
      setProcessing(false);
    }

    else {
      // store the end target
      localStorage.setItem('domain_target', res.data.target);
      login();
    }

    return;
  }
  
  // login
  const login = async () => {
    setProcessing(true);

    var reelaApi:any = new ReeLaAPI();
    reelaApi.baseAPI = localStorage.getItem('domain_target');
    reelaApi.countryCode = countryCode;
    reelaApi.phone = phone;
    reelaApi.password = password;

    var res:any = await reelaApi.login();

    if(!res) {
      if(process.env.NODE_MODE === 'development') console.error('Error with the response');

      setModal({
        visible: true,
        title: t('g.failedToProcessRequest'),
        message: t('g.failedToProcessRequestMessage'),
      });

      setProcessing(false);
      return false;
    }

    if(res.status === 'success') {
      // store the auth token
      localStorage.setItem('auth_token', res.data.user.auth_token);
      localStorage.setItem('device_uuid', res.data.user.device_uuid);

      getProfile(res.data.user.id);
    }
    else {
      // set the error messages
      setModal({
        visible: true,
        title: t('g.failedToProcessRequest'),
        message: t('s.auth.incorrectLoginInformation')
      });

      setProcessing(false);
      return false;
    }
  }

  const getProfile = async (profileId:string) => {
    var reelaApi:any = new ReeLaAPI();
    reelaApi.baseAPI = localStorage.getItem('domain_target');
    reelaApi.authToken = localStorage.getItem('auth_token') || null;
    reelaApi.profileId = profileId;

    var res:any = await reelaApi.getProfile();

    if(!res) {
      if(process.env.NODE_MODE === 'development') console.error('Failed to retrieve profile', res);

      setProcessing(false);
      return null;
    }

    if(res.data && res.data) {
      // Store the profile
      localStorage.setItem('profile', JSON.stringify(res.data));
      
      getSettings();
    }


    else {
      setModal({
        visible: true,
        title: t('g.failedToProcessRequest'),
        message: t('g.failedToProcessRequestMessage')
      });

      setProcessing(false);
      return null;
    }
  }

  // get company settings
  const getSettings = async () => {
    var reelaApi:any = new ReeLaAPI;
    reelaApi.baseAPI = localStorage.getItem('domain_target');
    reelaApi.authToken = localStorage.getItem('auth_token');

    var res:any = await reelaApi.getSettings();

    if(!res) {
      if(process.env.NODE_MODE === 'development') {
        console.error('Error with the response');
      }

      setModal({
        visible: true,
        title: t('g.failedToProcessRequest'),
        message: t('g.failedToProcessRequestMessage'),
        actions: [
          {
            content: t('g.tryAgain'),
            onClick: () => getSettings()
          },
          {
            content: t('g.cancel'),
            onClick: () => null
          },
        ]
      });
    }

    else if(res.status === "fail"){
      setModal({
        visible: true,
        title: t('g.failedToProcessRequest'),
        message: reelaApi.getErrorsString(),
        actions: [
          {
            content: t('g.tryAgain'),
            onClick: () => getSettings()
          },
          {
            content: t('g.cancel'),
            onClick: () => null
          },
        ]
      });
    } 

    if(
      res.status === "success" 
      && res.data
    ) {
      // save the related settings
      var settings = res.data;
      
      for(var i = 0; i < settings.length; i++) {
        if(settings[i].uuid === 'currency-key') {
          localStorage.setItem('currency-key', settings[i].value);
        }

        //.. can add any future needed settings here
      }

      // redirect the user
      if(location.pathname === '/login') {
        navigate('/');
        window.location.reload();
      }
      else {
        navigate(0);
      }
    }
  }

  return <>
    <Modal
      size='mini'
      onClose={() => setModal(null)}
      open={(modal && modal.visible) ? true : false}
      header={modal ? modal.title : null}
      content={modal ? modal.message : null}
      actions={['Ok']}
    />
    <SiteHeader hideLogo />
    <Grid 
      textAlign='center'
      verticalAlign='middle'
      style={{height: '100vh' }}
      padded
    >
      <Grid.Column style={{ maxWidth: 450 }}>
        <div style={{justifyContent: 'center', display: 'flex'}}>
          <img src={Logo} width="100" />
        </div>
        
        <Header as='h1' textAlign='center'>
          {t('g.welcomeTo')} {t('g.appName')}
          <Header.Subheader>
            {t('s.auth.loginDescription')}
          </Header.Subheader>
        </Header>
        <Form size='large' onSubmit={() => processDomain()}>
          <Form.Field>
            <Input
              required
              actionPosition='left'
              action={
                <Dropdown 
                  button 
                  basic 
                  floating 
                  options={[
                    {key: '+966', text: 'SA | +966', value: '+966'},
                    {key: '+20', text: 'EG | +20', value: '+20'}
                  ]} 
                  defaultValue={countryCode}
                  onChange={(e, { name, value }) => {
                    if(value && typeof value === 'string') {
                      setCountryCode(value);
                      setDomain(value.substring(1));
                    }
                  }}
                />
              }
              value={(phone) ? phone : ''}
              onChange={(e, { value }) => {
                if(value[0] !== '0') setPhone(value)
              }}
              placeholder='56xxx'
              fluid
              autoComplete={false}
            />
          </Form.Field>
          <Form.Input
            required
            fluid
            icon='lock'
            iconPosition='left'
            placeholder={t('g.password')}
            type='password'
            onChange={(e, { value }) => setPassword(value)}
          />
          
          <Button 
            primary
            fluid 
            size='large'
            submit
            loading={processing}
          >
            {t('s.auth.login')}
          </Button>

          <br />
          
          <Button 
            fluid 
            onClick={() => navigate('/password-reset')}
            loading={processing}
          >
            {t('s.auth.resetMyPassword')}
          </Button>

        </Form>
      </Grid.Column>
    </Grid>
  </>
}