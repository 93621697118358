/*
 *
 * PROJECT REELA
 * Developed by:  3WebBox LLC - 2022
 * 
 * Disclaimor: Please make sure to read related documentation before
 * making any changes to the code. Modify the code under your own
 * responsibility. for help please contact 3WebBox.
 * 
 * https://3webbox.com  : support@3webbox.com
 * 
 * 
 */

export default {
  renderLocal: (language, originalContent) => {
    var content = originalContent;

    if(content && typeof content === 'string') {
      try {
        content = JSON.parse(content);

        if(content[language]) return content[language];
        else if (content['en']) return content['en']
        else return '/** Error';
      }
      catch(e) {
        console.warn('Failed to parse value', e);
        return '/** Error';
      }
    }

    else if (content && typeof content === 'object') {
      if(content[language]) return content[language];
      else if (content['en']) return content['en']
      else return '/** Error';
    }

    else return '/** N01C0N';
  }
}