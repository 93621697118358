import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

// import component
import { Icon, Grid, Header, Button, Divider } from 'semantic-ui-react';

export default function Unauthorized(props:any) {
  const {t, i18n} = useTranslation();
  const navigate = useNavigate();
  
  return <>
    <Grid 
      textAlign='center'
      verticalAlign='middle'
      style={{height: '100%'}}
      padded
    >
      <Grid.Column style={{ maxWidth: 450 }}>

        <Icon name='exclamation triangle' size='big' color='grey' />

        <Header as='h1'>
          {t('s.auth.unauthorizedAccess')}
          <Header.Subheader>
            {t('s.auth.unauthorizedAccessDescription')}
          </Header.Subheader>
        </Header>

        <Divider hidden />

        <Button
          basic
          label={t('g.goBack')}
          labelPosition='right'
          icon='arrow left'
          onClick={() => navigate(-1)}
        />

        <Button
          basic
          label={t('g.reportProblem')}
          labelPosition='left'
          icon='bug'
          color='red'
        />

      </Grid.Column>
    </Grid>
  </>
}