import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';

// import ReeLa library
import ReeLaAPI from '../util/reela_lib';

// import config
import { config } from '../config';

import { 
  Button,
  Grid, 
  Header
} from 'semantic-ui-react';

import Logo from '../assets/logo-icon.svg';

export default function Logout() {
  const navigate = useNavigate();
  const {t, i18n} = useTranslation();

  useEffect(() => {
    logout();
  }, []);
  
  var logout = async() => {
    var reelaApi:any = new ReeLaAPI;
    reelaApi.baseAPI = localStorage.getItem('domain_target');
    reelaApi.authToken = localStorage.getItem('auth_token');
    reelaApi.deviceUuid = localStorage.getItem('device_uuid');

    var res:any = await reelaApi.logout();

    if(!res) {
      // show error
      console.warn('Failed to logout', res);
    }

    // clear all the neccessary stored values
    localStorage.removeItem('domain_target');
    localStorage.removeItem('auth_token');
    localStorage.removeItem('device_uuid');
    localStorage.removeItem('profile');

    // must remove the logout flag from the URL to prevent the system
    // from removing the localstorage when the component refresh to
    // process the login
    window.location.href = process.env.REACT_APP_PORTAL_URI + 'login';
  }

  return <>
    <Grid textAlign='center' verticalAlign='middle' style={{height: '100vh' }} padded>
      <Grid.Column style={{ maxWidth: 450 }}>
        <div style={{justifyContent: 'center', display: 'flex'}}>
          <img src={Logo} width="100" />
        </div>
        
        <Header as='h1' textAlign='center'>
          {t('s.auth.bye')}
        </Header>
        <p>{t('s.auth.byeDescription')}</p>

        <Button primary onClick={() => navigate('/login')}>
          {t('s.auth.login')}
        </Button>
      </Grid.Column>
    </Grid>
  </>
}