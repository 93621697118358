import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { 
  Button,
  Divider,
  Grid,
  Header, 
  Image,
  Input,
  Modal 
} from "semantic-ui-react";

import { config } from '../config';
import ReeLaAPI from "../util/reela_lib";

const ProductImages = (props:any) => {
  const {t, i18n} = useTranslation();
  
  // processing states
  const [isProcessingImage, setIsProcessingImage] = useState<boolean>(false);

  const [images, setImages] = useState<any[]>(props.images);
  const [selectedImage, setSelectedImage] = useState<any>(null);
  const [productId, setProductId] = useState<any[]|null>(props.productId);

  const [modal, setModal] = useState<any>();

  useEffect(() => {
    // set the product id if not provided
    if(
      props.images &&
      props.images.length > 0 && 
      props.images[0].product_id
    ) {
      setProductId(props.images[0].product_id);
    }

    // set the product id if provided in a prop
    else if(props.productId) setProductId(props.productId);
  }, []);

  useEffect(() => { setImages(props.images) }, [props.images]);

  useEffect(() => { uploadImage() }, [selectedImage]);

  const updateDefaultImage = async (id:string) => {
    if(isProcessingImage || !id) return null;

    setIsProcessingImage(true);

    var reelaApi:any = new ReeLaAPI;
    reelaApi.baseAPI = localStorage.getItem('domain_target');
    reelaApi.authToken = localStorage.getItem('auth_token');

    reelaApi.productId = props.productId;
    reelaApi.defaultImageId = id;
    
    var res:any = await reelaApi.updateProduct();

    setIsProcessingImage(false);

    if(process.env.NODE_MODE === 'development') console.log('processProduct response:', res);

    if(!res || res.status !== 'success') {
      
      // error
      setModal({
        visible: true,
        title: t("g.failedToProcessRequest"),
        message: reelaApi.getErrorsString(),
        actions: [{ content: t('g.ok') }]
      });
    }

    else {
      setModal({
        visible: true,
        title: t("g.processCompleted"),
        message: t("g.processCompletedMessage"),
        actions: [{ content: t('g.ok'), positive: true }]
      })
    }

    setIsProcessingImage(false);

    return null;
  }

  const uploadImage = async () => {
    // > Validate image uploading
    if(isProcessingImage || !selectedImage) return null;

    setIsProcessingImage(true);

    const data:any = new FormData();
    data.append("image", selectedImage);

    var reelaApi:any = new ReeLaAPI;
    reelaApi.baseAPI = localStorage.getItem('domain_target');
    reelaApi.debug = process.env.REACT_APP_MODE === 'development' ? true : false;
    reelaApi.authToken = localStorage.getItem('auth_token');
    reelaApi.productId = props.productId
    reelaApi.body = data;
    reelaApi.useAppend = true;
    
    var res:any = await reelaApi.uploadProductImage();
    setIsProcessingImage(false);

    if(!res || res.status !== 'success') {
      if(process.env.NODE_MODE === 'development') console.warn('uploadImage error', res);

      setModal({
        visible: true,
        title: t("g.failedToProcessRequest"),
        message: reelaApi.getErrorsString(),
        actions: [{ content: t('g.ok') }]
      });
    }
    else {
      var updatedImages = images || [];

      setImages([...updatedImages, res.data]);
    }

    return null;
  }

  const deleteImage = async (id:string|null) => {
    if(isProcessingImage || !id) return null;

    setIsProcessingImage(true);

    var reelaApi:any = new ReeLaAPI;
    reelaApi.baseAPI = localStorage.getItem('domain_target');
    reelaApi.debug = process.env.REACT_APP_MODE === 'development' ? true : false;
    reelaApi.authToken = localStorage.getItem('auth_token');
    reelaApi.imageId = id;
    
    var res:any = await reelaApi.deleteProductImage();
    setIsProcessingImage(false);

    if(!res || res.status !== 'success') {
      if(process.env.NODE_MODE === 'development') console.warn('uploadImage error', res);

      setModal({
        visible: true,
        title: t("g.failedToProcessRequest"),
        message: reelaApi.getErrorsString(),
        actions: [{ content: t('g.ok') }]
      });
    }
    else {
      // loop images to remove the deleted image
      var cleanedImages:any[] = [];
      var currentImagesList = images || [];

      currentImagesList.map((image:any, key:number) => {
        if(image.id !== id) cleanedImages.push(image); 
      })

      setImages(cleanedImages);
    }

    return null;
  }

  const _renderImages = () => {
    if(!images || images.length < 1) return null;

    var render:any[] = [];

    // map images
    images.map((image:any, key:number) => {
      var defaultColor:'grey'|'blue' = 'grey';

      if(image.id === props.defaultImageId) 
        defaultColor = 'blue';

      var baseAPI = localStorage.getItem('domain_target');
      var imagePath = `${baseAPI}images/products/${image.product_id}/${image.file_name}.${image.ext}`;
      
      render.push(
        <Grid.Column verticalAlign='bottom'>
          <Image 
            src={imagePath}
            size='medium'
            rounded
            label={{ 
              as: 'a', 
              corner: 'left', 
              icon: 'star', 
              color: defaultColor 
            }}
            onClick={() => window.open(imagePath, "_blank")}
          />
          {props.management &&
          <div style={{marginTop: 10}}>
            <Button
              size='mini'
              icon='trash'
              content={t('g.delete')}
              negative
              onClick={() => setModal({
                visible: true,
                title: t("g.areYouSure"),
                message: `${t("g.deletingRecordMessage")} ${t("g.processIsNotReversable")}`,
                actions: [
                  { 
                    content: t("g.confirm"),
                    negative: true, 
                    onClick: () => deleteImage(image.id)
                  },
                  {
                    content: t("g.cancel"),
                  }
                ]
              })}
            />
            <Button
              size='mini'
              icon='star'
              content={t('g.setDefault')}
              onClick={() => updateDefaultImage(image.id)}
            />
          </div>}
        </Grid.Column>
      )
    })

    return render;
  }

  return <>
    <Modal
      size='mini'
      onClose={() => setModal(null)}
      open={(modal && modal.visible) ? true : false}
      header={modal ? modal.title : null}
      content={modal ? modal.message : null}
      actions={modal ? modal.actions : ['Close']}
    />

    <Divider hidden />

    <Header as='h2' content='Images' />

    <Grid stackable columns={4}>
      {(!images || images.length < 1) && <div>
        No images found  
      </div>}
      {_renderImages()}
    </Grid>

    <Divider hidden />

    {(props.productId && props.management) && <>
      <Input
        type='file'
        accept='image/*'
        onChange={(event, e) => {
          if(event && event.target && event.target.files) {
            var file:any = event.target.files[0];
            setSelectedImage(file);
          }
        }} 
        style={{display: 'none'}}
        id='image-upload-selector'
      />

      <Button
        type='submit'
        content={t('g.upload')}
        icon='upload'
        primary
        loading={isProcessingImage}
        disabled={isProcessingImage}
        onClick={() => {
          document.getElementById('image-upload-selector')?.click()
        }}
      />
    </>}
  </>
}

export default ProductImages;