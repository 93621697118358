import { useState } from 'react';
import { useNavigate, useLocation } from 'react-router'

import { 
  Form, 
  Grid, 
  Header, 
  Button,
  Modal,
  Input
} from 'semantic-ui-react';

// import SVG
import Logo from '../assets/logo-icon.svg';

// import jmla_lib
import ReeLaAPI from '../util/reela_lib';

// import app config
import { config } from '../config';

// translation
import { useTranslation } from "react-i18next";
import SiteHeader from '../framework/site_header';

export default function PasswordReset() {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();

  // states
  interface modalType {
    visible: boolean,
    title: string,
    message: string
  }

  const [modal, setModal] = useState<modalType|null>(null);
  const [processing, setProcessing] = useState<boolean>(false);

  // login information states
  const [phone, setPhone] = useState<string|null>(null);
  const [otpRequested, setOtpRequested] = useState<boolean>(false);
  const [otp, setOtp] = useState<string|null>(null);
  const [password, setPassword] = useState<string|null>(null);

  const [errors, setErrors] = useState<any>(null);

  const countryCode = '+966';
  var returnPath:any = null;

  if(
    location.pathname === '/login' ||
    location.pathname === '/password-reset'
  )
    returnPath = () => { navigate('/') }
  else
    returnPath = () => navigate(0);

  const requestOTP = async () => {
    setProcessing(false);
    // send the OTP request
    // >
    
    var reelaApi:any = new ReeLaAPI;
    reelaApi.baseAPI = localStorage.getItem('domain_target');
    reelaApi.phone = phone;
    reelaApi.countryCode = countryCode;

    var res:any = await reelaApi.sendOTP();
    
    if(res && res.status === 'success') {
      setOtpRequested(true);
    }

    else if(res && res.errors && res.errors.length > 0 ) {
      setModal({
        visible: true,
        title: t('g.failedToProcessRequest'),
        message: reelaApi.getErrorsString()
      });
    }

    setProcessing(false);
    return null;
  }

  // reset the password
  const resetPassword = async () => {
    setProcessing(true);

    var reelaApi:any = new ReeLaAPI();
    reelaApi.baseAPI = localStorage.getItem('domain_target');
    reelaApi.passwordResetMethod = 'phone';
    reelaApi.countryCode = countryCode;
    reelaApi.phone = phone;
    reelaApi.password = password;
    reelaApi.code = otp;

    var res:any = await reelaApi.resetPassword();

    if(!res) {
      if(process.env.NODE_MODE === 'development') console.error('Error with the response');

      setModal({
        visible: true,
        title: t('g.failedToProcessRequest'),
        message: t('g.failedToProcessRequestMessage'),
      });

      setProcessing(false);
      return false;
    }

    if(res.status === 'success') {
      login();
    }
    else {
      // set the error messages
      setModal({
        visible: true,
        title: t('g.failedToProcessRequest'),
        message: reelaApi.getErrorsString()
      });

      setProcessing(false);
      return false;
    }
  }

  // login
  const login = async () => {
    setProcessing(true);

    var reelaApi:any = new ReeLaAPI();
    reelaApi.baseAPI = localStorage.getItem('domain_target');
    reelaApi.countryCode = countryCode;
    reelaApi.phone = phone;
    reelaApi.password = password;

    var res:any = await reelaApi.login();

    if(!res) {
      if(process.env.NODE_MODE === 'development') console.error('Error with the response');

      setModal({
        visible: true,
        title: t('g.failedToProcessRequest'),
        message: t('g.failedToProcessRequestMessage'),
      });

      setProcessing(false);
      return false;
    }

    if(res.status === 'success') {
      // store the auth token
      localStorage.setItem('auth_token', res.data.user.auth_token);
      localStorage.setItem('device_uuid', res.data.user.device_uuid);

      getProfile(res.data.user.id);
    }
    else {
      // set the error messages
      setModal({
        visible: true,
        title: t('g.failedToProcessRequest'),
        message: t('g.failedToProcessRequestMessage')
      });

      setProcessing(false);
      return false;
    }
  }

  const getProfile = async (profileId:string) => {
    var reelaApi:any = new ReeLaAPI();
    reelaApi.baseAPI = localStorage.getItem('domain_target');
    reelaApi.authToken = localStorage.getItem('auth_token') || null;
    reelaApi.profileId = profileId;

    var res:any = await reelaApi.getProfile();

    if(!res) {
      if(process.env.NODE_MODE === 'development') console.error('Failed to retrieve profile', res);

      setProcessing(false);
      return null;
    }

    if(res.data && res.data.length > 0) {
      // Store the profile
      localStorage.setItem('profile', JSON.stringify(res.data[0]));

      // redirect the user
      returnPath();

      // end the loading
      setProcessing(false);
      return null;
    }


    setModal({
      visible: true,
      title: t('g.failedToProcessRequest'),
      message: t('g.failedToProcessRequestMessage')
    });

    setProcessing(false);
    return null;
  }

  const _renderResetBtn = () => {
    var label = t('g.reset');
    var action:any = () => null;

    if(!otpRequested) {
      action = () => requestOTP()
    }

    if(otpRequested) {
      label = `${t('g.update')} ${t('g.password')}`
      action = () => resetPassword()
    }

    return <Button 
      fluid 
      onClick={action}
      loading={processing}
    >
      {label}
    </Button>
  }

  return <>
    <Modal
      size='mini'
      onClose={() => setModal(null)}
      open={(modal && modal.visible) ? true : false}
      header={modal ? modal.title : null}
      content={modal ? modal.message : null}
      actions={['Ok']}
    />

    <SiteHeader hideLogo />
    
    <Grid 
      textAlign='center'
      verticalAlign='middle'
      style={{height: '100vh' }}
      padded
    >
      <Grid.Column style={{ maxWidth: 450 }}>
        <div style={{justifyContent: 'center', display: 'flex'}}>
          <img src={Logo} width="100" />
        </div>
        
        <Header as='h1' textAlign='center'>
          {t('s.auth.resetMyPassword')}
          <Header.Subheader>
            {t('s.auth.resetMyPasswordDescription')}
          </Header.Subheader>
        </Header>
        <Form size='large'>
          <Form.Field>
            <Input
              required
              label={countryCode}
              labelPosition='left'
              value={(phone) ? phone : ''}
              onChange={(e, { value }) => {
                if(value[0] !== '0') setPhone(value)
              }}
              placeholder='56xxx'
              fluid
            />
          </Form.Field>
          {(!otpRequested) ? null : <div>
            <p style={{textAlign: 'left'}}>
              {t('s.auth.phoneOtpDescription')} (<span className='link' onClick={requestOTP}>{t('s.auth.resendOtp')}</span>)
            </p>
            <Form.Input
              required
              fluid
              icon='lock'
              iconPosition='left'
              placeholder={t('s.auth.phoneOtp')}
              onChange={(e, { value }) => setOtp(value)}
            /> 

            <p style={{textAlign: 'left'}}>
              {t('g.password')}:
            </p>
            <Form.Input
              required
              fluid
              icon='lock'
              iconPosition='left'
              placeholder={t('g.password')}
              type='password'
              onChange={(e, { value }) => setPassword(value)}
            /> 

            <br />
          </div> }
          
          {_renderResetBtn()}

          <div style={{padding: 20}}>
            <a href="/login">
              {t("s.auth.login")}
            </a>
          </div>
        </Form>
      </Grid.Column>
    </Grid>
  </>
}