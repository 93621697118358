/*
 *
 *  ReeLa CORE JS Library
 *  This library intended to provide methods and functions
 *  to communicate with ReeLa CORE APIs.
 * 
 *  Version: BETA 0.5
 *  Code version: 00002
 *  Last update: 02/28/2023 @rafetkhallaf
 *  Author: 3WebBox LLC
 * 
 */

export default class ReeLaAPI {
  constructor() {
    this.baseAPI = null;
    this.method = 'GET';
    this.headers = null;
    this.authToken = null;
    this.additionalHeaders = null;
    this.query = null;
    this.body = {};
    this.res = {
      code: null,
      status: null,
      message: null,
      data: null,
      currentPage: null,
      resPerPage: null,
      errors: null,
      update_status: null
    };
    this.lang = 'en';
  }

  async isReachable() {
    const timeout = new Promise((resolve, reject) => {
        setTimeout(reject, 5000, 'Request timed out');
    });

    const request = fetch(this.baseAPI);

    try {
      const response = await Promise
        .race([timeout, request]);
      return true;
    }
    catch (error) {
      console.error('Cannot reach host', this.baseAPI);
      return false;
    }
  }

  async request() {
    if(!this.url) {
      console.log('URL is required to make a process');
      return false;
    }

    // set default hedaers
    if(!this.headers && !this.useAppend) {
      this.headers = {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      }
    } 
    else {
      this.headers = {};
    }

    if(this.authToken) {
      this.headers.authorization = `Bearer ${this.authToken}`;
    }

    if(this.additionalHeaders) {
      this.headers = {
        ...this.headers,
        ...this.additionalHeaders        
      }
    }

    if(this.method == 'GET') {
      this.body = null;
    }
    else if (this.useAppend) {
      this.body = this.body;
    }
    else {
      this.body = JSON.stringify(this.body);
    }

    await fetch(this.url, {
      method: this.method,
      headers: this.headers,
      body: this.body
    })
    .then((response) => {
      var resJson = response.json();
      this.res.code = response.status;

      return resJson;
    })
    .then((json) => {
      if( this.debug ) console.log(json); 

      var res = json;

      // set response status
      this.res.status = res.status;

      if(res.status == 'fail') {
        var errors = [];
        // this means return errors list
        if(res.errors) {
          res.errors.map( error => {
            errors.push(error[this.lang])
          })

          this.res.errors = errors;
        }
      }
      else {
        if(res.message)
          this.res.message = res.message[this.lang];

        if(res.data)
          this.res.data = res.data;

        if(res.current_page)
          this.res.currentPage = res.current_page;

        if(res.res_per_page)
          this.res.resPerPage = res.res_per_page;

        if(res.update_status)
          this.res.update_status = res.update_status;
      }
    })
    .catch((error) => {
      console.error(error);
      this.res.errors = ["Could not make the request - check logs"];
    });

    return this.res;
  }

  getErrorsString() {
    var errors = this.res.errors;
    var errorsString = '';

    if(!errors || errors.length < 1) { return ''; }

    errors.map( (error) => {
      if(errorsString != '') { errorsString += '\r\n' }

      if(errors.length > 1) errorsString += '\u2022 ';
      errorsString += error;
    });
    
    return errorsString;
  }


  /**
   * 
   * 
   * DORES DISPATCH URLs
   * 
   * 
   */

  /**
   * GET DOMAIN DETAILS
   */

  async getDomain() {
    if(!this.baseAPI) {
      console.error('baseAPI path is not set');
      return null; 
    }
    
    if(!this.domain) {
      console.error('Domain is required to getDomain');
      return null;
    }

    this.url = `${this.baseAPI}cores/${this.domain}`;
    this.method = 'GET';

    return await this.request();
  }

  /**
   * GET GLOBAL SETTINGS
   */

  async getDispatchGlobalSettings() {
    if(!this.baseAPI) {
      console.error('baseAPI path is not set');
      return null; 
    }

    this.url = `${this.baseAPI}settings`;
    this.method = 'GET';

    return await this.request();
  }


  /*
   *
   * Proceess login
   * 
   */ 

  async sendOTP() {
    var type = null;
    var data = {};

    // check if email
    // check if phone

    if(!this.email && !this.phone) {
      console.error('email or phone is required');
      return null; 
    }

    if(this.email) {
      type = 'email';
      data = { email : this.email }
    }

    if(this.phone) {
      type = 'phone';
      
      if(!this.countryCode) {
        console.error('countryCode is required for phone type OTP');
        return null;
      }

      data = {
        phone: this.phone,
        country_code: this.countryCode
      }
    }

    if(!this.baseAPI) {
      console.error('baseAPI path is not set');
      return null; 
    }

    this.url = `${this.baseAPI}users/send-otp`;
    this.method = 'POST';
    this.body = {
      type: type,
      data: JSON.stringify(data)
    }

    return await this.request();
  }


  /*
   *
   * Proceess login
   * 
   */ 

  async login() {
    if(!this.baseAPI) {
      console.error('baseAPI path is not set');
      return null; 
    }
    
    var errors = false;
    var countryCode = this.countryCode || null;
    var phone = this.phone || null;
    var username = this.username || null;
    var email = this.email || null;
    var password = this.password || null;
    var otpCode = this.otpCode || null;
    var pushToken = this.pushToken || null;

    if(!username && !phone && !email) {
      console.error('Must provide an identification');
      errors = true;
    }

    if(phone && !countryCode) {
      console.error('Country code is required to sign in with phone');
      errors = true;
    }

    if(!password && !otpCode) {
      console.error(
        'Password or OTP is required',
        password,
        otpCode
      );

      errors = true;
    }

    if(errors) { console.error('1x255875'); return false; }

    this.url = `${this.baseAPI}users/login`;
    this.method = 'POST';

    if(password)
      this.body = {
        password: password
      };
    
    else if(otpCode)
      this.body = {
        otp_code: otpCode
      };

    if(phone && countryCode) {
      this.body = {
        ...this.body,
        phone: phone,
        country_code: countryCode
      }
    }
    
    if(email) {
      this.body = {
        ...this.body,
        email: email
      }
    }

    if(this.pushToken) {
      this.body = {
        ...this.body,
        push_token: pushToken
      }
    }

    return await this.request();
  }


  /*
   *
   * Proceess logout
   * 
   */ 

  async logout() {
    if(!this.baseAPI) {
      console.error('baseAPI path is not set');
      return null; 
    }
    
    if(!this.deviceUuid && !this.allDevices) {
      console.error('deviceUuid is required [deviceUuid, allDevices]');
      return null; 
    }

    if(this.deviceUuid) {
      this.query = `device_uuid=${this.deviceUuid}`
    }

    else if (this.allDevices) {
      this.query = `all_devices=${this.allDevices}`
    }

    this.url = `${this.baseAPI}users/logout/?${this.query}`;
    this.method = 'GET';

    return await this.request();
  }


  /*
   *
   * Quick Register
   * 
   */ 

  async quickRegister() {
    if(!this.baseAPI) {
      console.error('baseAPI path is not set');
      return null; 
    }

    this.body = {};

    if(this.otpCode) {
      this.body = {
        ...this.body,
        otp_code: this.otpCode
      }
    }

    if(this.password) {
      this.body = {
        ...this.body,
        password: this.password
      }
    }

    if(this.countryCode) {
      this.body = {
        ...this.body,
        country_code: this.countryCode
      }
    }

    if(this.phone) {
      this.body = {
        ...this.body,
        phone: this.phone
      }
    }

    if(this.email) {
      this.body = {
        ...this.body,
        email: this.email
      }
    }

    if(this.nameLocal) {
      this.body = {
        ...this.body,
        name_local: this.nameLocal
      }
    }

    if(this.taxId) {
      this.body = {
        ...this.body,
        tax_id: this.taxId
      }
    }

    if(this.licenseNumber) {
      this.body = {
        ...this.body,
        license_number: this.licenseNumber
      }
    }

    if(this.location) {
      this.body = {
        ...this.body,
        location: this.location
      }
    }

    if(this.serviceCityId) {
      this.body = {
        ...this.body,
        service_city_id: this.serviceCityId
      }
    }

    if(this.address1) {
      this.body = {
        ...this.body,
        address_1: this.address1
      }
    }

    if(!this.authToken) {
      console.error('authToken is required');
      return null;
    }

    this.url = `${this.baseAPI}customers/quick-register${this.authToken ? '' : '/guest'}`;
    this.method = 'POST';

    return await this.request();
  }


  /*
   *
   * Retrieve categories
   * 
   */ 

  async getCategories() {
    if(!this.baseAPI) {
      console.error('baseAPI path is not set');
      return null; 
    }

    if(!this.authToken) {
      console.error('authToken is required');
      return null;
    }

    if(!this.query) this.query = '';

    if(this.active)
      this.query += `active=${this.active}&`;

    if(this.searchTerm)
      this.query += `searchTerm=${this.searchTerm}&`;

    if(this.categoryId)
      this.query += `parentId=${this.categoryId}&`

    if(this.page)
      this.query += `page=${this.page}&`;

    if(this.resPerPage)
      this.query += `resPerPage=${this.resPerPage}&`;

    if(this.listAll)
      this.query += `listAll=${this.listAll}&`;  

    this.url = `${this.baseAPI}categories/list?${this.query}`;
    return await this.request();
  }

  /*
   *
   * Retrieve products list
   * 
   */ 

  async getProducts() {
    if(!this.baseAPI) {
      console.error('baseAPI path is not set');
      return null; 
    }

    this.query = '';

    if(this.vendorId)
      this.query += `vendorId=${this.vendorId}&`;

    if(this.customerId)
      this.query += `customerId=${this.customerId}&`;

    if(this.categoryId)
      this.query += `categoryId=${this.categoryId}&`;

    if(this.searchTerm)
      this.query += `searchTerm=${this.searchTerm}&`;

    if(this.isAvailable)
      this.query += `isAvailable=${this.isAvailable}&`;

    if(this.isVisible)
      this.query += `isVisible=${this.isVisible}&`;

    if(this.serviceCityId)
      this.query += `serviceCityId=${this.serviceCityId}&`;

    if(this.showAll)
      this.query += `showAll=${this.showAll}&`;

    if(this.page)
      this.query += `page=${this.page}&`;

    if(this.resPerPage)
      this.query += `resPerPage=${this.resPerPage}&`;

    if(this.orderDirection)
      this.query += `orderDirection=${this.orderDirection}&`;

    if(this.orderByColumn)
      this.query += `orderByColumn=${this.orderByColumn}&`;

    this.url = `${this.baseAPI}products/list?${this.query}`;
    
    return await this.request();
  }


  /*
   *
   * Retrieve a specific product
   * 
   */ 

  async getProduct() {
    if(!this.baseAPI) {
      console.error('baseAPI path is not set');
      return null; 
    }

    if(!this.productId) {
      console.error('productId was not set');
      return null; 
    }

    this.url = `${this.baseAPI}products/show/${this.productId}`;

    return await this.request();
  }


  /*
   *
   * Create a product
   * 
   */ 

  async createProduct() {
    if(!this.baseAPI) {
      console.error('baseAPI path is not set');
      return null; 
    }

    //
    this.body = {};

    // business uuid and category
    if(this.categoryId) this.body = { 
      ...this.body, 
      category_id: this.categoryId 
    };

    if(this.sku) this.body = { 
      ...this.body, 
      sku: this.sku 
    };

    if(this.barcode) this.body = { 
      ...this.body, 
      barcode: this.barcode 
    };

    if(this.listingOrder) this.body = { 
      ...this.body, 
      listing_order: this.listingOrder 
    };

    if(this.isVisible) this.body = { 
      ...this.body, 
      is_visible: this.isVisible 
    };

    if(this.isAvailable) this.body = { 
      ...this.body, 
      is_available: this.isAvailable 
    };

    // name and description
    if(this.nameLocal) this.body = { 
      ...this.body, 
      name_local: this.nameLocal 
    };

    if(this.descriptionLocal) this.body = { 
      ...this.body, 
      description_local: this.descriptionLocal 
    };

    this.url = `${this.baseAPI}products/create/`;
    this.method = 'POST';

    return await this.request();
  }


  /*
   *
   * Retrieve a specific product
   * 
   */ 

  async updateProduct() {
    if(!this.baseAPI) {
      console.error('baseAPI path is not set');
      return null; 
    }

    if(!this.productId) {
      console.error('productUuid was not set');
      return null; 
    }

    // In update, business uuid can only be updated from the database
    // this function can be added to the library if needed later on

    this.body = {};

    // business uuid and category
    if(this.categoryId) this.body = { 
      ...this.body, 
      category_id: this.categoryId 
    };

    if(this.sku) this.body = { 
      ...this.body, 
      sku: this.sku 
    };

    if(this.barcode) this.body = { 
      ...this.body, 
      barcode: this.barcode 
    };

    if(this.isAvailable) this.body = { 
      ...this.body, 
      is_available: this.isAvailable 
    };

    if(this.isVisible) this.body = { 
      ...this.body, 
      is_visible: this.isVisible 
    };
    
    if(this.listingOrder) this.body = { 
      ...this.body, 
      listing_order: this.listingOrder 
    };

    // name and description
    if(this.nameLocal) this.body = { 
      ...this.body, 
      name_local: this.nameLocal
    };

    if(this.descriptionLocal) this.body = {
      ...this.body,
      description_local: this.descriptionLocal
    };
    // default image
    if(this.defaultImageId) this.body = {
      ...this.body,
      default_image_id: this.defaultImageId
    };

    this.url = `${this.baseAPI}products/update/${this.productId}`;
    this.method = 'PUT';

    return await this.request();
  }

  

  /*
   *
   * Upload product image
   * 
   */

  async uploadProductImage() {
    if(!this.baseAPI) {
      console.error('baseAPI path is not set');
      return null; 
    }

    if(!this.productId) {
      console.error('productId was not set');
      return null; 
    }

    this.method = 'POST';
    this.url = `${this.baseAPI}products/images/upload/${this.productId}`;

    return await this.request();
  }


  /*
   *
   * Delete a specific product image
   * 
   */ 

  async deleteProductImage() {
    if(!this.baseAPI) {
      console.error('baseAPI path is not set');
      return null; 
    }

    if(!this.imageId) {
      console.error('imageUuid was not set');
      return null; 
    }

    this.url = `${this.baseAPI}products/images/delete/${this.imageId}`;
    this.method = 'DELETE';

    return await this.request();
  }

  

  /*
   *
   * Add product price
   * 
   */

  async createProductPrice() {
    if(!this.baseAPI) {
      console.error('baseAPI path is not set');
      return null; 
    }

    if(!this.productId) {
      console.error('productId was not set');
      return null; 
    }

    if(!this.serviceCityId) {
      console.error('serviceCityId was not set');
      return null; 
    }

    if(!this.tierPrice) {
      console.error('tierPrice was not set');
      return null; 
    }

    if(!this.tierMaxQuantity && this.tierMaxQuantity !== 0) {
      console.error('tierMaxQuantity was not set');
      return null; 
    }

    this.body = {
      service_city_id: this.serviceCityId,
      tier_price: this.tierPrice,
      tier_max_quantity: this.tierMaxQuantity
    }

    this.method = 'POST';
    this.url = `${this.baseAPI}products/prices/create/${this.productId}`;

    return await this.request();
  }

  /*
   *
   * Update product price
   * 
   */

  async updateProductPrice() {
    if(!this.baseAPI) {
      console.error('baseAPI path is not set');
      return null; 
    }

    if(!this.priceId) {
      console.error('priceId was not set');
      return null; 
    }

    if(!this.tierPrice) {
      console.error('tierPrice was not set');
      return null; 
    }

    if(!this.tierMaxQuantity) {
      console.error('tierMaxQuantity was not set');
      return null; 
    }

    this.body = {
      tier_price: this.tierPrice,
      tier_max_quantity: this.tierMaxQuantity
    }

    this.method = 'PUT';
    this.url = `${this.baseAPI}products/prices/update/${this.priceId}`;

    return await this.request();
  }

  /*
   *
   * Update product price
   * 
   */

  async deleteProductPrice() {
    if(!this.baseAPI) {
      console.error('baseAPI path is not set');
      return null; 
    }

    if(!this.priceId) {
      console.error('priceId was not set');
      return null; 
    }

    this.method = 'DELETE';
    this.url = `${this.baseAPI}products/prices/delete/${this.priceId}`;

    return await this.request();
  }

  
  /*
   *
   * Set vendor product
   * 
   */

  async setVendorProduct() {
    if(!this.baseAPI) {
      console.error('baseAPI path is not set');
      return null; 
    }

    if(!this.productId) {
      console.error('productId was not set');
      return null; 
    }

    if(!this.price) {
      console.error('price was not set');
      return null; 
    }

    this.body = {
      price: this.price
    }

    if(this.vendorId) this.body = {
      ...this.body,
      vendor_id: this.vendorId
    }

    this.method = 'POST';
    this.url = `${this.baseAPI}products/vendors/set/${this.productId}`;

    return await this.request();
  }
  

  
  /*
   *
   * Remove vendor product
   * 
   */

  async removeVendorProduct() {
    if(!this.baseAPI) {
      console.error('baseAPI path is not set');
      return null; 
    }

    if(!this.productId) {
      console.error('productId was not set');
      return null; 
    }

    if(!this.productId) {
      console.error('productId was not set');
      return null; 
    }
    
    if(this.vendorId) this.body = {
      ...this.body,
      vendor_id: this.vendorId
    }

    this.method = 'DELETE';
    this.url = `${this.baseAPI}products/vendors/remove/${this.productId}`;

    return await this.request();
  }



  /*
   *
   * Create a cart
   * 
   */ 

  async createCart() {
    if(!this.baseAPI) {
      console.error('baseAPI path is not set');
      return null; 
    }

    if(!this.authToken) {
      console.error('authToken was not set');
      return null; 
    }

    if(!this.authToken && !this.customerId) {
      console.error('customerId was not set');
      return null; 
    }

    if(this.customerId) {
      this.body = {
        customer_id: this.customerId
      }
    }

    this.url = `${this.baseAPI}carts/create`;
    this.method = 'POST';

    return await this.request();
  }

  /*
   *
   * Retrieve a cart
   * 
   */ 

  async getCarts() {
    if(!this.baseAPI) {
      console.error('baseAPI path is not set');
      return null; 
    }

    if(!this.authToken) {
      console.error('authToken was not set');
      return null; 
    }

    this.query = '';

    if(this.customerId)
      this.query += `customerId=${this.customerId}&`;
      
    if(this.registrarId)
      this.query += `registrarId=${this.registrarId}&`;

    if(this.searchTerm)
      this.query += `searchTerm=${this.searchTerm}&`;

    if(this.serviceCityId)
      this.query += `serviceCityId=${this.serviceCityId}&`;

    if(this.fromDate)
      this.query += `fromDate=${this.fromDate}&`;
      
    if(this.toDate)
      this.query += `toDate=${this.toDate}&`;

    if(this.page)
      this.query += `page=${this.page}&`;

    if(this.resPerPage)
      this.query += `resPerPage=${this.resPerPage}&`;

    if(this.orderDirection)
      this.query += `orderDirection=${this.orderDirection}&`;

    if(this.orderByColumn)
      this.query += `orderByColumn=${this.orderByColumn}&`;

    this.url = `${this.baseAPI}carts/list?${this.query}`;

    return await this.request();
  }
  


  /*
   *
   * Retrieve a cart
   * 
   */ 

  async getCart() {
    if(!this.baseAPI) {
      console.error('baseAPI path is not set');
      return null; 
    }

    if(!this.cartUuid) {
      console.error('cartUuid path is not set');
      return null; 
    }

    this.url = `${this.baseAPI}carts/show/${this.cartUuid}`;

    return await this.request();
  }


  /*
   *
   * Add item to cart
   * 
   */ 

  async addItemToCart() {
    if(!this.baseAPI) {
      console.error('baseAPI path is not set');
      return null; 
    }

    if(!this.authToken) {
      console.error('authToken was not set');
      return null; 
    }

    if(!this.cartUuid) {
      console.error('cartUuid was not set');
      return null; 
    }

    if(!this.productId) {
      console.error('productId  was not set');
      return null; 
    }

    if(!this.quantity) {
      console.error('quantity was not set');
      return null; 
    }

    this.url = `${this.baseAPI}carts/items/create/${this.cartUuid}`;
    
    this.body = {
      product_id: this.productId,
      quantity: this.quantity
    }

    this.method = 'POST';

    return await this.request();
  }


  /*
   *
   * Delete item from cart
   * 
   */ 

  async deleteItemFromCart() {
    if(!this.baseAPI) {
      console.error('baseAPI path is not set');
      return null; 
    }

    if(!this.authToken) {
      console.error('authToken was not set');
      return null; 
    }

    if(!this.cartItemId) {
      console.error('cartItemId was not set');
      return null; 
    }

    this.url = `${this.baseAPI}carts/items/delete/${this.cartItemId}`;
    this.method = 'DELETE';

    return await this.request();
  }

  /*
   *
   * Delete  cart
   * 
   */ 

  async deleteCart() {
    if(!this.baseAPI) {
      console.error('baseAPI path is not set');
      return null; 
    }

    if(!this.authToken) {
      console.error('authToken was not set');
      return null; 
    }

    if(!this.cartUuid) {
      console.error('cartUuid was not set');
      return null; 
    }

    this.url = `${this.baseAPI}carts/delete/${this.cartUuid}`;
    this.method = 'DELETE';

    return await this.request();
  }
  


  /*
   *
   * submit an order
   * 
   */ 

  async submitOrder() {
    if(!this.baseAPI) {
      console.error('baseAPI path is not set');
      return null; 
    }

    if(!this.authToken) {
      console.error('authToken was not set');
      return null; 
    }

    if(!this.cart_uuid) {
      console.error('cart_uuid was not set');
      return null; 
    }

    if(!this.payment_method) {
      console.error('payment_method was not set');
      return null; 
    }

    if(!['cash', 'bank transfer', 'credit card'].includes(this.payment_method)) {
      console.error('Provided payment method is not allowed');
      return null; 
    }

    if(!this.delivery_period) {
      console.error('delivery_period was not set');
      return null; 
    }

    if(!this.delivery_shift) {
      console.error('delivery_shift was not set');
      return null; 
    }

    this.body = {
      payment_method: this.payment_method,
      delivery_period: this.delivery_period,
      delivery_shift: this.delivery_shift
    }

    this.url = `${this.baseAPI}orders/create/${this.cart_uuid}`;
    this.method = 'POST';

    return await this.request();

  }

  /*
   *
   * Return user profile
   * 
   */ 

  async getProfile() {
    if(!this.baseAPI) {
      console.error('baseAPI path is not set');
      return null; 
    }
    
    this.url = `${this.baseAPI}users/show/${this.profileId}`;

    this.method = 'GET';
    this.authToken = this.authToken

    return await this.request();
  }


  /*
   *
   * Retrieve orders
   * 
   */ 

  async getOrders() {
    if(!this.baseAPI) {
      console.error('baseAPI path is not set');
      return null; 
    }

    if(!this.authToken && !this.customerPhone) {
      console.error('Must provide authToken or customerPhone to retrieve orders');
      return null; 
    }

    if(!this.query) this.query = '';

    if(this.page) {
      this.query += `page=${this.page}&`;
    }

    if(this.resPerPage) {
      this.query += `resPerPage=${this.resPerPage}&`;
    }

    if(this.searchTerm) {
      this.query += `searchTerm=${this.searchTerm}&`;
    }

    if(this.orderBy) {
      this.query += `&orderBy=${this.orderBy}`
    }

    if(this.orderDirection) {
      this.query += `&orderDirection=${this.orderDirection}`
    }

    if(this.paid) {
      this.query += `&paid=${this.paid}`
    }

    if(this.status) {
      this.query += `&status=${this.status}`
    }

    if(this.fromDate) {
      this.query += `&fromDate=${this.fromDate}`
    }

    if(this.toDate) {
      this.query += `&toDate=${this.toDate}`
    }

    if(this.driverId) {
      this.query += `&driverId=${this.driverId}`
    }

    if(this.registrarId) {
      this.query += `&registrarId=${this.registrarId}`
    }

    this.url = `${this.baseAPI}orders/list?${this.query}`;
    return await this.request();
  }

  /*
   *
   * Retrieve orders products
   * 
   */ 

  async getPurchaseList() {
    if(!this.baseAPI) {
      console.error('baseAPI path is not set');
      return null; 
    }

    if(!this.authToken) {
      console.error('Must provide authToken to retrieve orders');
      return null; 
    }
    
    if(!this.query) this.query = '';
    
    if(this.searchTerm) {
      this.query += `&searchTerm=${this.searchTerm}`;
    }

    if(this.orderStatus) {
      this.query += `&orderStatus=${this.orderStatus}`
    }

    if(this.serviceCityId) {
      this.query += `&serviceCityId=${this.serviceCityId}`
    }

    if(this.page) {
      this.query += `&page=${this.page}`;
    }

    if(this.resPerPage) {
      this.query += `&resPerPage=${this.resPerPage}`;
    }


    if(this.orderBy) {
      this.query += `&orderBy=${this.orderBy}`
    }

    if(this.orderDirection) {
      this.query += `&orderDirection=${this.orderDirection}`
    }

    if(this.showAll) {
      this.query += `&showAll=y`
    }


    this.url = `${this.baseAPI}orders/list/purchase-list?${this.query}`;
    return await this.request();
  }
  

  /*
   *
   * Retrieve order
   * 
   */ 

  async getOrder() {
    if(!this.baseAPI) {
      console.error('baseAPI path is not set');
      return null; 
    }

    if(!this.orderUuid) {
      console.error('Must provide orderUuid to retrieve order');
      return null; 
    }

    if(!this.authToken) {
      this.url = `${this.baseAPI}orders/show/guest/${this.orderUuid}`;
    }
    else {
      this.url = `${this.baseAPI}orders/show/${this.orderUuid}`;
    }
    
    return await this.request();
  }


  /*
   *
   * Delete order
   * 
   */ 

  async deleteOrder() {
    if(!this.baseAPI) {
      console.error('baseAPI path is not set');
      return null; 
    }

    if(!this.orderUuid) {
      console.error('Must provide orderUuid to retrieve order');
      return null; 
    }

    if(this.hardDelete) {
      this.body = {
        ...this.body,
        hard_delete: this.hardDelete
      }
    };
    
    this.url = `${this.baseAPI}orders/delete/${this.orderUuid}`;
    this.method = 'DELETE';
    return await this.request();
    
  }


  /*
   *
   * Update order
   * 
   */ 

  async updateOrder() {
    if(!this.baseAPI) {
      console.error('baseAPI path is not set');
      return null; 
    }

    if(!this.authToken) {
      console.error('Must provide authToken to retrieve order');
      return null; 
    }

    if(!this.orderUuid) {
      console.error('Must provide orderUuid to retrieve order');
      return null; 
    }

    this.body = {};

    if(this.status) {
      this.body = {
        ...this.body,
        status: this.status
      }
    };

    if(this.driverId) {
      this.body = {
        ...this.body,
        driver_id: this.driverId
      }
    };

    this.url = `${this.baseAPI}orders/update/${this.orderUuid}`;
    this.method = 'PUT';
    
    return await this.request();
  }


  /*
   *
   * Generate Invoice
   * 
   */ 

  async generateOrderInvice() {
    if(!this.baseAPI) {
      console.error('baseAPI path is not set');
      return null; 
    }

    if(!this.authToken) {
      console.error('Must provide authToken to send the invoice');
      return null; 
    }

    if(!this.orderUuid) {
      console.error('Must provide orderUuid to send the invoice');
      return null; 
    }

    this.url = `${this.baseAPI}orders/show/${this.orderUuid}/?download=y`;
    this.method = 'GET';
    
    return await this.request();
  }


  /*
   *
   * Send Invoice
   * 
   */ 

  async sendInvoice() {
    if(!this.baseAPI) {
      console.error('baseAPI path is not set');
      return null; 
    }

    if(!this.authToken) {
      console.error('Must provide authToken to send the invoice');
      return null; 
    }

    if(!this.orderUuid) {
      console.error('Must provide orderUuid to send the invoice');
      return null; 
    }

    if(!this.type) {
      console.error('Must provide type [email, phone]');
      return null; 
    }

    this.body = {};

    if(this.orderUuid) {
      this.body = {
        ...this.body,
        order_uuid: this.orderUuid
      }
    };

    if(this.type) {
      this.body = {
        ...this.body,
        type: this.type
      }
    };

    this.url = `${this.baseAPI}orders/send-invoice`;
    this.method = 'POST';
    
    return await this.request();
  }


  /*
   *
   * Get setting
   * 
   */ 

  async getSetting() {
    if(!this.baseAPI) {
      console.error('baseAPI path is not set');
      return null; 
    }
    
    if(!this.key) {
      console.error('key path is not set');
      return null; 
    }
    
    if(this.authToken) 
      this.url = `${this.baseAPI}system/settings/show/${this.key ? this.key : ''}`;
    else
      this.url = `${this.baseAPI}system/settings/public/show/${this.key ? this.key : ''}`;
    
    this.method = 'GET';

    return await this.request();
  }

  /*
   *
   * Get settings
   * 
   */ 

  async getSettings() {
    if(!this.baseAPI) {
      console.error('baseAPI path is not set');
      return null; 
    }

    if(!this.authToken) {
      console.error('Must provide authToken');
      return null; 
    }
    
    this.url = `${this.baseAPI}system/settings/list`;
    this.method = 'GET';

    return await this.request();
  }

  /*
   *
   * Get notifications
   * 
   */ 

  async getActivities() {
    if(!this.baseAPI) {
      console.error('baseAPI path is not set');
      return null; 
    }
    
    this.url = `${this.baseAPI}system/activities/list`;
    this.authToken = this.authToken;
    this.method = 'GET';

    return await this.request();
  }

  
  /*
   *
   * Get customers
   * 
   */ 
  
  async getCustomers() {
    if(!this.baseAPI) {
      console.error('baseAPI path is not set');
      return null; 
    }
    
    if(!this.query) this.query = '';

    if(this.page) {
      this.query += `page=${this.page}&`;
    }

    if(this.resPerPage) {
      this.query += `resPerPage=${this.resPerPage}&`;
    }

    if(this.searchTerm) {
      this.query += `searchTerm=${this.searchTerm}&`;
    }

    if(this.registrarId) {
      this.query += `registrarId=${this.registrarId}&`;
    }

    if(this.city) {
      this.query += `city=${this.city}&`
    }

    if(this.state) {
      this.query += `state=${this.state}&`
    }

    if(this.country) {
      this.query += `country=${this.country}&`
    }

    if(this.showAll) {
      this.query += `showAll=${this.showAll}&`
    }


    this.url = `${this.baseAPI}customers/list?${this.query}`;

    return await this.request();  
  }

  /*
   *
   * Retrieve service cities 
   * 
   */ 

  async getServiceCities() {
    if(!this.baseAPI) {
      console.error('baseAPI path is not set');
      return null; 
    }

    this.url = `${this.baseAPI}system/service-cities/list`;

    return await this.request();
  }


  /*
   *
   * Return list of users
   * 
   */ 

  async getUsers() {
    if(!this.baseAPI) {
      console.error('baseAPI path is not set');
      return null; 
    }

    var query = '';

    if(this.searchTerm) {
      query += `searchTerm=${this.searchTerm}&&`;
    }

    if(this.type) {
      query += `type=${this.type}&&`;
    }

    if(this.currentPage) {
      query += `page=${this.currentPage}&&`;
    }

    if(this.resPerPage) {
      query += `resPerPage=${this.resPerPage}&&`;
    }
    
    this.url = `${this.baseAPI}users/list?${query}`;
    this.method = 'GET';

    return await this.request();
  }


  /*
   *
   * Update user profile
   * 
   */ 

  async updateCustomer() {
    if(!this.baseAPI) {
      console.error('baseAPI path is not set');
      return null; 
    }
    
    this.body = {};

    if(this.registrarId) this.body = {
      ...this.body,
      registrar_id: this.registrarId 
    }

    if(this.nameLocal) this.body = {
      ...this.body,
      name_local: this.nameLocal 
    }

    if(this.taxId) this.body = {
      ...this.body,
      tax_id: this.taxId 
    }

    if(this.licenseNumber) this.body = {
      ...this.body,
      license_number: this.licenseNumber 
    }

    if(this.address1) this.body = {
      ...this.body,
      address_1: this.address1 
    }

    if(this.address2) this.body = {
      ...this.body,
      address_2: this.address2 
    }

    if(this.serviceCityId) this.body = {
      ...this.body,
      service_city_id: this.serviceCityId 
    }
        
    this.url = `${this.baseAPI}customers/update/${this.customerId}`;
    this.method = 'PUT';

    return await this.request();
  }


  /*
   *
   * Complete order delivery
   * 
   */ 

  async completeOrderDelivery() {
    if(!this.baseAPI) {
      console.error('baseAPI path is not set');
      return null; 
    }

    if(!this.authToken) {
      console.error('Must provide authToken to retrieve order');
      return null; 
    }

    if(!this.orderUuid) {
      console.error('Must provide orderUuid to retrieve order');
      return null; 
    }

    this.url = `${this.baseAPI}orders/complete-delivery/${this.orderUuid}`;
    this.method = 'POST';
    
    return await this.request();
  }


  /*
   *
   * Update Order Product
   * 
   */ 

  async updateOrderProduct() {
    if(!this.baseAPI) {
      console.error('baseAPI path is not set');
      return null; 
    }

    if(!this.authToken) {
      console.error('Must provide authToken to retrieve order');
      return null; 
    }

    if(!this.orderProductId) {
      console.error('Must provide orderProductId to retrieve order');
      return null; 
    }

    this.body = {};

    if(this.fulfillment) this.body = {
      ...this.body,
      fulfillment: this.fulfillment 
    }

    if(this.quantity) {
      this.body = {
        ...this.body,
        quantity: this.quantity
      }
    }

    if(this.productId) {
      this.body = {
        ...this.body,
        product_id: this.productId
      }
    }

    if(this.orderUuid) {
      this.body = {
        ...this.body,
        order_uuid: this.orderUuid
      }
    }

    this.url = `${this.baseAPI}orders-products/update/${this.orderProductId}`;
    this.method = 'PUT';
    
    return await this.request();
  }

  /*
   *
   * Delete Order Product
   * 
   */ 

  async deleteOrderProduct() {
    if(!this.baseAPI) {
      console.error('baseAPI path is not set');
      return null; 
    }

    if(!this.authToken) {
      console.error('Must provide authToken to delete order product');
      return null; 
    }

    if(!this.orderProductId) {
      console.error('Must provide orderProductId to delete order product');
      return null; 
    }

    if(!this.orderUuid) {
      console.error('Must provide orderUuid to delete order product');
      return null; 
    }

    this.body = {};

    if(this.orderUuid) {
      this.body = {
        ...this.body,
        order_uuid: this.orderUuid 
      }
    }
      
    this.url = `${this.baseAPI}orders-products/delete/${this.orderProductId}`;
    this.method = 'DELETE';
    
    return await this.request();
  }



  /*
   *
   *
   * OLD APIS FROM SOURCE COPIED FILE
   * TO BE REMOVED AFTER COMPLETING THE FILE
   * MOVE ANY CLEANED UP CODE OR USED IN THE NEW
   * CODE ABOVE THIS LINE AFTER TESTING
   * 
   * 
   * 
   */


  /*
   *
   * Proceess login
   * 
   */ 

  async sLogin() {
    var type = null;
    var data = {};

    // check if email
    // check if phone

    if(!this.email && !this.phone) {
      console.error('email or phone is required');
      return null; 
    }

    if(!this.otpCode) {
      console.error('OTP code is required for simple login');
      return null; 
    }

    if(!this.baseAPI) {
      console.error('baseAPI path is not set');
      return null; 
    }

    if(this.email) {
      type = 'email';
      data = { email : this.email }
    }

    if(this.phone) {
      type = 'phone';
      
      if(!this.countryCode) {
        console.error('countryCode is required for phone type OTP');
        return null;
      }

      data = {
        phone: this.phone,
        country_code: this.countryCode
      }
    }

    this.url = `${this.baseAPI}users/login/simple`;
    this.method = 'POST';
    this.body = {
      country_code: this.countryCode,
      phone: this.phone,
      otp_code: this.otpCode
    }

    if(this.pushToken) {
      this.body = {
        ...this.body,
        push_token: this.pushToken
      }
    }

    return await this.request();
  }


  /*
    *
    * Proceess registration
    * 
    */ 

  async register() {
    if(!this.baseAPI) {
      console.error('baseAPI path is not set');
      return null; 
    }
    
    var errors = false;
    var countryCode = this.countryCode;
    var phone = this.phone;
    var email = this.email;
    var password = this.password;
    var otpCode = this.otpCode;
    var type = this.type;
    var firstName = this.firstName;
    var lastName = this.lastName;
    var pushToken = this.pushToken;

    if(!phone || !email) {
      console.error('Phone or email is required');
      errors = true;
    }

    if(phone) {
      if(!countryCode) {
        console.error('CountryCode is required for phone type registration');
        errors = true;
      }
    }

    if(!otpCode) {
      console.error('OTP code is required to register');
      errors = true;
    }

    if(!password) {
      console.error('Password is required');
      errors = true;
    }

    if(errors) { console.error('1x255875'); return false; }

    this.url = `${this.baseAPI}users/signup`;
    this.method = 'POST';
    this.body = {
      country_code: countryCode,
      phone: phone,
      email: email,
      otp_code: otpCode,
      password: password,
      first_name: firstName,
      last_name: lastName,
      type: type,
      push_token: pushToken
    }

    return await this.request();
  }


  /*
   *
   * Update settings
   * 
   */ 

  async updateSettings() {
    if(!this.baseAPI) {
      console.error('baseAPI path is not set');
      return null; 
    }

    this.body = {}
    
    if(this.keys < 1) {
      console.error("there is no key to update");
    }

    this.keys.map(key => {
      this.body = {
        ...this.body,
        [key.uuid]:key.value
      }
    })

    this.url = `${this.baseAPI}system/settings/update`;
    this.method = 'PUT';

    return await this.request();

  }

  /*
   *
   * Retrieve businesses types
   * 
   */ 

  async getBusinessTypes() {
    if(!this.baseAPI) {
      console.error('baseAPI path is not set');
      return null; 
    }

    this.url = `${this.baseAPI}business-types/${this.authToken ? '' : 'guests/'}list`;

    if(this.businessTypeUuid) {
      this.url += '?businessTypeUuid=' +this.businessTypeUuid
    }

    return await this.request();
  }


  /*
   *
   * Update business type
   * 
   */ 

  async updateBusinessTypes() {
    if(!this.baseAPI) {
      console.error('baseAPI path is not set');
      return null; 
    }

    this.body = {}

    if(this.active) {
      this.body = {
        ...this.body,
        active: this.active
      }
    }

    if(this.listing) {
      this.body = {
        ...this.body,
        listing: this.listing
      }
    }

    if(this.titleLocal) {
      this.body = {
        ...this.body,
        title_local: this.titleLocal
      }
    }

    this.url = `${this.baseAPI}business-types/update/${this.businessTypeUuid}`;

    this.method = 'PUT';

    return await this.request();
  }


  /*
   *
   * Create business type
   * 
   */ 

  async createBusinessTypes() {
    if(!this.baseAPI) {
      console.error('baseAPI path is not set');
      return null; 
    }

    if(!this.titleLocal) {
      console.error('titleLocal is required to create business type');
      return null;
    }

    this.body = {
      active: this.active || 'n',
      listing: this.listing || 0,
      title_local: this.titleLocal
    }

    this.url = `${this.baseAPI}business-types/create/`;

    this.method = 'POST';

    return await this.request();
  }


  /*
   *
   * Retrieve businesses list
   * 
   */ 

  async getBusinesses() {
    if(!this.baseAPI) {
      console.error('baseAPI path is not set');
      return null; 
    }
    
    if(!this.query) this.query = '';

    if(this.page) {
      this.query += `page=${this.page}&`;
    }

    if(this.resPerPage) {
      this.query += `resPerPage=${this.resPerPage}&`;
    }

    if(this.searchTerm) {
      this.query += `searchTerm=${this.searchTerm}&`;
    }

    if(this.businessTypeUuid) {
      this.query += `businessTypeUuid=${this.businessTypeUuid}&`;
    }

    if(this.city) {
      this.query += `city=${this.city}&`
    }

    if(this.state) {
      this.query += `state=${this.state}&`
    }

    if(this.country) {
      this.query += `country=${this.country}&`
    }

    if(this.favorites) {
      this.query += `favorites=y&`
    }

    this.url = `${this.baseAPI}businesses/${this.authToken ? '' : 'guests/'}list?${this.query}`;

    return await this.request();    
  }


  /*
   *
   * Retrieve business info
   * 
   */ 

  async getBusiness() {
    if(!this.baseAPI) {
      console.error('baseAPI path is not set');
      return null; 
    }
    
    if(!this.authToken && !this.businessUuid) {
      console.error('businessUuid is required to getBusiness info');
      return null; 
    }

    this.url = `${this.baseAPI}businesses/${this.authToken ? '' : 'guests/'}show/${this.businessUuid}`;
    return await this.request();    
  }


  /*
   *
   * register business
   * 
   */ 

  async registerBusiness() {
    if(!this.baseAPI) {
      console.error('baseAPI path is not set');
      return null; 
    }
    
    if(!this.userUuid) {
      console.error('userUuid is required to createBusiness info');
      return null; 
    }  
    
    if(!this.businessTypeUuid) {
      console.error('businessTypeUuid is required to createBusiness info');
      return null; 
    }  
    
    if(!this.nameLocal) {
      console.error('nameLocal is required to createBusiness info');
      return null; 
    }

    // > check name local format
    
    if(!this.sloganLocal) {
      console.error('sloganLocal is required to createBusiness info');
      return null; 
    }

    // > check slogan local format
    
    if(!this.pickupAvailable) {
      console.error('pickupAvailable is required to createBusiness info');
      return null; 
    }
    
    if(!this.localShippingCost) {
      console.error('localShippingCost is required to createBusiness info');
      return null; 
    }
    
    if(!this.nationalShippingCost) {
      console.error('nationalShippingCost is required to createBusiness info');
      return null; 
    }
    
    if(!this.address) {
      console.error('address is required to createBusiness info');
      return null; 
    }
    
    if(!this.city) {
      console.error('city is required to createBusiness info');
      return null; 
    }
    
    if(!this.state) {
      console.error('state is required to createBusiness info');
      return null; 
    }
    
    if(!this.country) {
      console.error('country is required to createBusiness info');
      return null; 
    }
    
    // validate coords
    // if(this.coords) {
    //   console.error('coords is required to getBusiness info');
    //   return null; 
    // }
    
    if(!this.licenseNumber) {
      console.error('licenseNumber is required to getBusiness info');
      return null; 
    }
    
    if(!this.taxId) {
      console.error('taxId is required to getBusiness info');
      return null; 
    }
    
    if(!this.tax) {
      console.error('tax is required to getBusiness info');
      return null; 
    }

    // complete list of requirements

    this.body = {
      business_type_uuid: this.businessTypeUuid,
      user_uuid: this.userUuid,
      active: this.active,

      tax_id: this.taxId,
      tax: this.tax,
      license_number: this.licenseNumber,

      address: this.address,
      city: this.city,
      state: this.state,
      country: this.country,
      coords: this.coords,
      
      name_local: this.nameLocal,
      slogan_local: this.sloganLocal,
      
      local_shipping_cost: this.localShippingCost,
      national_shipping_cost: this.nationalShippingCost,
      pickup_available: this.pickupAvailable
    }

    this.url = `${this.baseAPI}businesses/register`;
    this.method = 'POST';
    
    return await this.request();    
  }


  /*
   *
   * create business
   * 
   */ 

  async createBusiness() {
    if(!this.baseAPI) {
      console.error('baseAPI path is not set');
      return null; 
    }
    
    if(!this.authToken) {
      console.error('Must be logged in to use this function');
      return null; 
    }
    
    if(!this.userUuid) {
      console.error('userUuid is required to createBusiness info');
      return null; 
    }  
    
    if(!this.businessTypeUuid) {
      console.error('businessTypeUuid is required to createBusiness info');
      return null; 
    }  
    
    if(!this.nameLocal) {
      console.error('nameLocal is required to createBusiness info');
      return null; 
    }

    // > check name local format
    
    if(!this.sloganLocal) {
      console.error('sloganLocal is required to createBusiness info');
      return null; 
    }

    // > check slogan local format
    
    if(!this.pickupAvailable) {
      console.error('pickupAvailable is required to createBusiness info');
      return null; 
    }
    
    if(!this.localShippingCost) {
      console.error('localShippingCost is required to createBusiness info');
      return null; 
    }
    
    if(!this.address) {
      console.error('address is required to createBusiness info');
      return null; 
    }
    
    if(!this.city) {
      console.error('city is required to createBusiness info');
      return null; 
    }
    
    if(!this.state) {
      console.error('state is required to createBusiness info');
      return null; 
    }
    
    if(!this.country) {
      console.error('country is required to createBusiness info');
      return null; 
    }
    
    // validate coords
    // if(this.coords) {
    //   console.error('coords is required to getBusiness info');
    //   return null; 
    // }
    
    if(!this.licenseNumber) {
      console.error('licenseNumber is required to getBusiness info');
      return null; 
    }

    // complete list of requirements

    this.body = {
      business_type_uuid: this.businessTypeUuid,
      user_uuid: this.userUuid,
      active: this.active,

      tax_id: this.taxId,
      tax: this.tax,
      license_number: this.licenseNumber,

      address: this.address,
      city: this.city,
      state: this.state,
      country: this.country,
      coords: this.coords,
      
      name_local: this.nameLocal,
      slogan_local: this.sloganLocal,
      
      local_shipping_cost: this.localShippingCost,
      national_shipping_cost: this.nationalShippingCost,
      pickup_available: this.pickupAvailable
    }

    this.url = `${this.baseAPI}businesses/create`;
    this.method = 'POST';
    
    return await this.request();    
  }


  /*
   *
   * Update business info
   * 
   */ 

  async updateBusiness() {
    if(!this.baseAPI) {
      console.error('baseAPI path is not set');
      return null; 
    }
    
    if(!this.authToken) {
      console.error('Must be logged in to use this function');
      return null; 
    }    
    
    if(!this.businessUuid) {
      console.error('businessUuid is required to getBusiness info');
      return null; 
    }

    this.body = {};

    // business assets
    if(this.removeLogoImage) {
      this.body = {
        ...this.body, 
        remove_logo_image: true
      }
    }

    if(this.removeHeaderImage) {
      this.body = {
        ...this.body, 
        remove_header_image: true
      }
    }

    if(this.removeAdvBannerImage) {
      this.body = {
        ...this.body, 
        remove_adv_banner_image: true
      }
    }

    // business type and owner
    if(this.businessTypeUuid) {
      this.body = {
        ...this.body, 
        business_type_uuid: this.businessTypeUuid
      }
    }

    if(this.userUuid) {
      this.body = {
        ...this.body, 
        user_uuid: this.userUuid
      }
    }

    if(this.active) {
      this.body = {
        ...this.body, 
        active: this.active
      }
    }

    // tax and licenses
    if(this.taxId) {
      this.body = {
        ...this.body, 
        tax_id: this.taxId
      }
    }

    if(this.tax) {
      this.body = {
        ...this.body, 
        tax: this.tax
      }
    }

    if(this.licenseNumber) {
      this.body = {
        ...this.body, 
        license_number: this.licenseNumber
      }
    }

    if(this.bankIban) {
      this.body = {
        ...this.body, 
        bank_iban: this.bankIban
      }
    }

    // address and location
    if(this.address) {
      this.body = {
        ...this.body, 
        address: this.address
      }
    }

    if(this.city) {
      this.body = {
        ...this.body, 
        city: this.city
      }
    }

    if(this.state) {
      this.body = {
        ...this.body, 
        state: this.state
      }
    }

    if(this.country) {
      this.body = {
        ...this.body, 
        country: this.country
      }
    }

    if(this.coords) {
      this.body = {
        ...this.body, 
        coords: this.coords
      }
    }

    // business locals
    if(this.nameLocal) {
      this.body = {
        ...this.body, 
        name_local: this.nameLocal
      }
    }

    if(this.sloganLocal) {
      this.body = {
        ...this.body, 
        slogan_local: this.sloganLocal
      }
    }

    // business shipping fees and information
    if(this.localShippingCost) {
      this.body = {
        ...this.body, 
        local_shipping_cost: this.localShippingCost
      }
    }

    if(this.nationalShippingCost) {
      this.body = {
        ...this.body, 
        national_shipping_cost: this.nationalShippingCost
      }
    }

    if(this.pickupAvailable) {
      this.body = {
        ...this.body,
        pickup_available: this.pickupAvailable
      }
    }

    this.url = `${this.baseAPI}businesses/update/${this.businessUuid}`;
    this.method = 'PUT';
    
    return await this.request();    
  }


  /*
   *
   * Update business info
   * 
   */ 

  async deleteBusiness() {
    if(!this.baseAPI) {
      console.error('baseAPI path is not set');
      return null; 
    }
    
    if(!this.authToken) {
      console.error('Must be logged in to use this function');
      return null; 
    }    
    
    if(!this.businessUuid) {
      console.error('businessUuid is required to getBusiness info');
      return null; 
    }

    this.url = `${this.baseAPI}businesses/delete/${this.businessUuid}`;
    this.method = 'DELETE';
    
    return await this.request();    
  }


  /*
   *
   * Retrieve adv
   * 
   */ 

  async getAdv() {
    if(!this.baseAPI) {
      console.error('baseAPI path is not set');
      return null; 
    }

    this.query = '';

    if(this.type) {
      this.query += `type=${this.type}&`;
    }

    if(this.approved) {
      this.query += `approved=${this.approved}&`;
    }

    if(this.active) {
      this.query += `active=${this.active}&`;
    }

    if(this.businessTypeUuid) {
      this.query += `businessTypeUuid=${this.businessTypeUuid}&`;
    }

    if(this.businessUuid) {
      this.query += `businessUuid=${this.businessUuid}&`;
    }

    if(this.city) {
      this.query += `city=${this.city}&`
    }

    if(this.state) {
      this.query += `state=${this.state}&`
    }

    if(this.country) {
      this.query += `country=${this.country}&`
    }

    if(this.page) {
      this.query += `page=${this.page}&`;
    }

    if(this.resPerPage) {
      this.query += `resPerPage=${this.resPerPage}&`;
    }

    this.url = `${this.baseAPI}adv/${this.authToken ? '' : 'guests/'}list?${this.query}`;

    return await this.request();    
  }


  /*
   *
   * Update adv
   * 
   */ 

  async updateAdv() {
    if(!this.baseAPI) {
      console.error('baseAPI path is not set');
      return null; 
    }

    if(!this.authToken) {
      console.error('authToken path is not set');
      return null; 
    }

    if(!this.advUuid) {
      console.error('advUuid path is not set');
      return null; 
    }

    this.body = {};

    if(this.approved) {
      this.body = {
        ...this.body,
        approved: this.approved
      }
    }

    this.url = `${this.baseAPI}adv/update/${this.advUuid}`;
    this.method = 'PUT';

    return await this.request();    
  }


  /*
   *
   * Retrieve a category
   * 
   */ 

  async getCategory() {
    if(!this.baseAPI) {
      console.error('baseAPI path is not set');
      return null; 
    }

    if(!this.categoryId) {
      console.error('categoryId was not set');
      return null; 
    }

    this.url = `${this.baseAPI}categories/${this.authToken ? '' : 'guests/'}show/${this.categoryId}`;

    return await this.request();
  }


  /*
   *
   * Create a category
   * 
   */ 

  async createCategory() {
    if(!this.baseAPI) {
      console.error('baseAPI path is not set');
      return null; 
    }

    if(!this.authToken) {
      console.error('authToken is required for this request');
      return null; 
    }

    if(!this.titleLocal) {
      console.error('titleLocal path is not set');
      return null; 
    }

    if(typeof this.titleLocal !== 'object') {
      console.error('titleLocal must by of time bject with at least "en" key');
      return null;
    }

    if(!this.active) { this.active = 'y' }

    this.body = {
      business_uuid: this.businessUuid,
      title_local: this.titleLocal,
      description_local: this.descriptionLocal,
      active: this.active,
      parent_id: this.parentId
    };

    this.url = `${this.baseAPI}categories/create`;
    this.method = 'POST';

    return await this.request();
  }


  /*
   *
   * Update a category
   * 
   */ 

  async updateCategory() {
    if(!this.baseAPI) {
      console.error('baseAPI path is not set');
      return null; 
    }

    if(!this.authToken) {
      console.error('authToken is required for this request');
      return null; 
    }

    if(!this.categoryId) {
      console.error('categoryId path is not set');
      return null; 
    }

    if(!this.titleLocal) {
      console.error('titleLocal path is not set');
      return null; 
    }

    if(typeof this.titleLocal !== 'object') {
      console.error('titleLocal must by of time bject with at least "en" key');
      return null;
    }

    if(!this.active) { this.active = 'n' }

    this.body = {
      title_local: this.titleLocal,
      description_local: this.descriptionLocal,
      active: this.active,
      parent_id: this.parentId,
      order: this.order
    };

    this.url = `${this.baseAPI}categories/update/${this.categoryId}`;
    this.method = 'PUT';

    return await this.request();
  }


  /*
   *
   * Delete a category
   * 
   */ 

  async deleteCategory() {
    if(!this.baseAPI) {
      console.error('baseAPI path is not set');
      return null; 
    }

    if(!this.authToken) {
      console.error('authToken is required for this request');
      return null; 
    }

    if(!this.categoryId) {
      console.error('categoryId path is not set');
      return null; 
    }

    if(this.migrateTo) {
      this.body = {
        migrate_to: this.migrateTo,
      };
    }

    this.url = `${this.baseAPI}categories/delete/${this.categoryId}`;
    this.method = 'DELETE';

    return await this.request();
  }


  /*
   *
   * Delete a specific product
   * 
   */ 

  async deleteProduct() {
    if(!this.baseAPI) {
      console.error('baseAPI path is not set');
      return null; 
    }

    if(!this.productUuid) {
      console.error('productUuid was not set');
      return null; 
    }

    this.url = `${this.baseAPI}products/delete/${this.productUuid}`;
    this.method = 'DELETE';

    return await this.request();
  }


  /*
   *
   * Create a product
   * 
   */ 

  async getProductOptions() {
    if(!this.baseAPI) {
      console.error('baseAPI path is not set');
      return null; 
    }

    if(!this.productUuid) {
      console.error('productUuid was not set');
      return null; 
    }

    this.url = `${this.baseAPI}products/options/list/${this.productUuid}`;

    return await this.request();
  }


  /*
   *
   * Create new product option
   * 
   */ 

  async createProductOptions() {
    if(!this.baseAPI) {
      console.error('baseAPI path is not set');
      return null; 
    }

    if(!this.productUuid) {
      console.error('productUuid was not set');
      return null; 
    }

    if(!this.labelLocal) {
      console.error('labelLocal was not set');
      return null; 
    }

    if(!this.descriptionLocal) {
      console.error('labelLocal was not set');
      return null; 
    }

    this.body = {};

    this.body = {
      label_local: this.labelLocal,
      description_local: this.descriptionLocal,
      price: this.price,
      active: this.active || 'n'
    }

    this.url = `${this.baseAPI}products/options/create/${this.productUuid}`;
    this.method = 'POST';

    return await this.request();
  }



  /*
   *
   * Update a specific product option
   * 
   */ 

  async updateProductOptions() {
    if(!this.baseAPI) {
      console.error('baseAPI path is not set');
      return null; 
    }

    if(!this.optionUuid) {
      console.error('optionUuid was not set');
      return null; 
    }

    this.body = {};
    
    if(this.active) {
      this.body = {
        ...this.body,
        active: this.active
      }
    }
    
    if(this.labelLocal) {
      this.body = {
        ...this.body,
        label_local: this.labelLocal
      }
    }
    
    if(this.descriptionLocal) {
      this.body = {
        ...this.body,
        description_local: this.descriptionLocal
      }
    }
    
    if(this.price) {
      this.body = {
        ...this.body,
        price: this.price
      }
    }

    this.url = `${this.baseAPI}products/options/update/${this.optionUuid}`;
    this.method = 'PUT';

    return await this.request();
  }



  /*
   *
   * Delete a specific product option
   * 
   */ 

  async deleteProductOptions() {
    if(!this.baseAPI) {
      console.error('baseAPI path is not set');
      return null; 
    }

    if(!this.optionUuid) {
      console.error('optionUuid was not set');
      return null; 
    }

    this.url = `${this.baseAPI}products/options/delete/${this.optionUuid}`;
    this.method = 'DELETE';

    return await this.request();
  }


  /*
   *
   * Update a cart
   * 
   */ 

  async updateCart() {
    if(!this.baseAPI) {
      console.error('baseAPI path is not set');
      return null; 
    }

    if(!this.cartUuid) {
      console.error('cartUuid was not set');
      return null; 
    }

    this.body = {};

    if(this.delivery_period) {
      this.body = { 
        ...this.body, 
        delivery_period: this.delivery_period 
      }
    }
    
    this.url = `${this.baseAPI}carts/${this.authToken ? '' : 'guests/'}update/${this.cartUuid}`;
    this.method = 'PUT';

    return await this.request();
  }

  
  /*
   *
   * Update item in cart
   * 
   */ 

  async updateItemInCart() {
    if(!this.baseAPI) {
      console.error('baseAPI path is not set');
      return null;
    }

    if(!this.cartItemId) {
      console.error('cartItemId was not set');
      return null; 
    }

    this.body = {
      cart_item_id: this.cartItemId
    };

    if(this.quantity) {
      this.body = { ...this.body, quantity: this.quantity }
    }

    this.url = `${this.baseAPI}carts/items/${this.authToken ? '' : 'guests/'}update/${this.cartItemId}`;
    this.method = 'PUT';

    return await this.request();
  }

  /*
   *
   * Create user profile
   * 
   */ 

  async createProfile() {
    if(!this.baseAPI) {
      console.error('baseAPI path is not set');
      return null; 
    }
    
    if(!this.type) {
      console.error('type is not set');
      return null; 
    }
    
    if(!this.firstName) {
      console.error('firstName is not set');
      return null; 
    }
    
    if(!this.lastName) {
      console.error('lastName is not set');
      return null; 
    }
    
    if(!this.email) {
      console.error('email is not set');
      return null; 
    }
    
    if(!this.countryCode) {
      console.error('countryCode is not set');
      return null; 
    }
    
    if(!this.phone) {
      console.error('phone is not set');
      return null; 
    }
    
    if(!this.password) {
      console.error('password is not set');
      return null; 
    }
    
    this.body = {
      first_name: this.firstName,
      last_name: this.lastName,
      country_code: this.countryCode,
      phone: this.phone,
      email: this.email,
      password: this.password,
      type: this.type
    }

    this.url = `${this.baseAPI}users/create/`;
    this.method = 'POST';

    return await this.request();
  }

  /*
   *
   * Delete user profile
   * 
   */ 

  async deleteProfile() {
    if(!this.baseAPI) {
      console.error('baseAPI path is not set');
      return null; 
    }

    if(!this.profileUuid) {
      console.error('profileUuid path is not set');
      return null; 
    }

    this.url = `${this.baseAPI}users/delete/${this.profileUuid}/`;
    this.method = 'DELETE';

    return await this.request();
  }

  /*
   *
   * Update user profile
   * 
   */ 

  async updateProfile() {
    if(!this.baseAPI) {
      console.error('baseAPI path is not set');
      return null; 
    }

    var profile = 'profile';

    if(this.profileId) profile = this.profileId;

    this.url = `${this.baseAPI}users/update/${profile}`;
    this.method = 'PUT';
    this.authToken = this.authToken;

    var body = {};

    // Handle push notification token enable and disable
    if(this.removePushToken) { 
      if(!this.deviceUuid) {
        console.error('Updating push token requires the device uuid');
      }
      else {
        body = { 
          ...body, 
          remove_push_token: true,
          device_uuid: this.deviceUuid
        }
      }
    }

    // handle removing the push token
    if(this.pushToken && !this.removePushToken) { 
      if(!this.deviceUuid) {
        console.error('Updating push token requires the device uuid');
      }
      else {
        body = {
          ...body, 
          push_token: this.pushToken,
          device_uuid: this.deviceUuid
        }
      }
    }

    if(this.firstName) { body = {...body, first_name: this.firstName }}
    if(this.lastName) { body = {...body, last_name: this.lastName }}
    if(this.prefLanguage) {body ={...body, pref_language: this.prefLanguage}}

    if(this.block) { body = {...body, block: this.block }}

    if(this.email) { 
      if(!this.code) {
        console.log('code is required to update email');
        return null;
      }
      
      body = { 
        ...body, 
        email: this.email, 
        code: this.code 
      }
    }

    if(this.phone) { 
      if(!this.countryCode) {
        console.log('countryCode is required to update email');
        return null;
      }
      
      if(!this.code) {
        console.log('code is required to update email');
        return null;
      }

      body = { 
        ...body, 
        phone: this.phone, 
        country_code: this.countryCode, 
        code: this.code 
      } 
    }

    if(this.password) { 
      if(!this.cPassword) {
        console.log('cPassword is required to update password');
        return null;
      }

      body = {
        ...body, 
        password: this.password,
        current_password: this.cPassword
      }
    }

    if(this.profileImage) {
      var image = this.profileImage;

      if(!image.uri) {
        console.log('image.uri is required. Check image {} format');
        return null;
      }

      if(!image.name) {
        console.log('image.name is required. Check image {} format');
        return null;
      }

      if(!image.type) {
        console.log('image.type is required. Check image {} format');
        return null;
      }

      if(!image.size) {
        console.log('image.size is required. Check image {} format');
        return null;
      }
      
      var body = new FormData();
      body.append("profile_image", image);

      var xhr = new XMLHttpRequest();
      xhr.withCredentials = true;

      xhr.addEventListener("readystatechange", function () {
        if (this.readyState === 4) {
          console.log(this.responseText);
        }
      });

      xhr.open("POST", this.url);

      xhr.setRequestHeader("Content-Type", "multipart/form-data");
      xhr.setRequestHeader("cache-control", "no-cache");
      xhr.setRequestHeader("authorization", `Bearer ${this.authToken}`);

      xhr.send(body);

      return xhr.response;
    }

    if(this.removeProfileImage) {body = {...body, remove_profile_image: true }}

    if(this.blocked) {body = {...body, blocked: this.blocked }}

    if(this.type) {body = {...body, type: this.type }}

    this.body = body;
    return await this.request();
  }
  

  /*
   *
   * Upload profile image
   * 
   */

  async uploadProfileImage() {
    if(!this.baseAPI) {
      console.error('baseAPI path is not set');
      return null; 
    }

    if(!this.profileId) {
      console.error('productId was not set');
      return null; 
    }

    this.method = 'PUT';
    this.url = `${this.baseAPI}users/update/${this.profileId}`;

    return await this.request();
  }


  /*
   *
   * Create Service City Request
   * 
   */ 

  async createServiceCity() {
    if(!this.baseAPI) {
      console.error('baseAPI path is not set');
      return null; 
    }

    if(!this.city) {
      console.error('city is not set');
      return null; 
    }

    if(!this.state) {
      console.error('state is not set');
      return null; 
    }

    if(!this.country) {
      console.error('country is not set');
      return null; 
    }

    if(!this.local) {
      console.error('local is not set');
      return null; 
    }

    this.body = {
      city: this.city,
      state: this.state,
      country: this.country,
      local: this.local
    }

    this.url = `${this.baseAPI}system/service-cities/create`;
    this.method = 'POST';

    return await this.request();
  }


  /*
   *
   * Update Service City Request
   * 
   */ 

  async updateServiceCity() {
    if(!this.baseAPI) {
      console.error('baseAPI path is not set');
      return null; 
    }

    if(!this.serviceCityId) {
      console.error('serviceCityId is not set');
      return null; 
    }

    if(!this.city) {
      console.error('city is not set');
      return null; 
    }

    if(!this.state) {
      console.error('state is not set');
      return null; 
    }

    if(!this.country) {
      console.error('country is not set');
      return null; 
    }

    if(!this.local) {
      console.error('local is not set');
      return null; 
    }

    this.body = {
      city: this.city,
      state: this.state,
      country: this.country,
      local: this.local
    }

    this.url = `${this.baseAPI}system/service-cities/update/${this.serviceCityId}`;
    this.method = 'PUT';

    return await this.request();
  }


  /*
   *
   * Delete Service City Request
   * 
   */ 

  async deleteServiceCity() {
    if(!this.baseAPI) {
      console.error('baseAPI path is not set');
      return null; 
    }

    if(!this.serviceCityId) {
      console.error('serviceCityId is not set');
      return null; 
    }

    this.url = `${this.baseAPI}system/service-cities/delete/${this.serviceCityId}`;
    this.method = 'DELETE';

    return await this.request();
  }

  /*
   *
   * Reset password
   * 
   */

  async resetPassword() {
    if(!this.baseAPI) {
      console.error('baseAPI path is not set');
      return null; 
    }

    if(this.passwordResetMethod) {
      if(!this.password || !this.phone || !this.code || !this.countryCode) {
        console.error('Password reset requirements are missing');
        return null;
      }

      this.body = {
        password_reset_method: this.passwordResetMethod,
        phone: this.phone,
        country_code: this.countryCode,
        otp_code: this.code,
        password: this.password
      }
    }
    else {
      console.error('Password reset method must be set');
      return null;
    }
    
    this.url = `${this.baseAPI}users/reset-password`;
    this.method = 'PUT';

    return await this.request();
  }

  /*
   *
   * export orders
   * 
   */ 

  async exportOrders() {
    if(!this.baseAPI) {
      console.error('baseAPI path is not set');
      return null; 
    }
  
    if(!this.authToken) {
      console.error('authToken is required');
      return null;
    }
  
    var query = '';
    
    if(this.fromDate) query += '&fromDate=' + this.fromDate;
    if(this.toDate) query += '&toDate=' + this.toDate;

    if(this.showDeleted) query += '&showDeleted=' + this.showDeleted;
    if(this.status) query += '&status=' + this.status;
    if(this.paid) query += '&paid=' + this.paid;
    if(this.column) query += '&column=' + this.column;
    if(this.orderUuid) query += '&orderUuid=' + this.orderUuid;
    if(this.customerId) query += '&customerId=' + this.customerId;
    if(this.driverId) query += '&driverId=' + this.driverId;
  
    this.url = `${this.baseAPI}system/exports/orders?${query}`;
    this.method = 'GET';
  
    return await this.request();
  }
  /*
   *
   * export order products
   * 
   */ 

  async exportOrderProducts() {
    if(!this.baseAPI) {
      console.error('baseAPI path is not set');
      return null; 
    }

    if(!this.authToken) {
      console.error('authToken is required');
      return null;
    }

    var query = '';
    
    if(this.fromDate) query += '&fromDate=' + this.fromDate;
    if(this.toDate) query += '&toDate=' + this.toDate;

    if(this.showDeleted) query += '&showDeleted=' + this.showDeleted;
    if(this.status) query += '&status=' + this.status;
    if(this.paid) query += '&paid=' + this.paid;
    if(this.orderUuid) query += '&orderUuid=' + this.orderUuid;
    if(this.customerId) query += '&customerId=' + this.customerId;
    if(this.driverId) query += '&driverId=' + this.driverId;

    this.url = `${this.baseAPI}system/export/orders-products?${query}`;
    this.method = 'GET';

    return await this.request();
  }

  /*
   *
   * Retrieve payments
   * 
   */ 

  async getPayments() {
    if(!this.baseAPI) {
      console.error('baseAPI path is not set');
      return null; 
    }

    var query = '';
    
    if(this.currentPage) {
      query += `page=${this.currentPage}&&`;
    }

    if(this.resPerPage) {
      query += `resPerPage=${this.resPerPage}&&`;
    }
    
    this.url = `${this.baseAPI}system/payments/list?${query}`;
    this.method = 'GET';

    return await this.request();
  }

  /*
   *
   * Get the OTPs 
   * 
   */ 

  async getOTPs() {
    if(!this.baseAPI) {
      console.error('baseAPI path is not set');
      return null; 
    }

    if(!this.authToken) {
      console.error('authToken is required');
      return null;
    }

    var query = '';

    if(this.searchTerm) {
      query += `searchTerm=${this.searchTerm}&`;
    }
    
    if(this.type) {
      query += `type=${this.type}&`;
    }

    if(this.orderDirection) {
      query += `orderDirection=${this.orderDirection}&`;
    }

    if(this.orderByColumn) {
      query += `orderByColumn=${this.orderByColumn}&`;
    }

    if(this.currentPage) {
      query += `page=${this.currentPage}&`;
    }

    if(this.resPerPage) {
      query += `resPerPage=${this.resPerPage}&`;
    }

    this.url = `${this.baseAPI}system/otp/list?${query}`;
    this.method = 'GET';

    return await this.request();
  }

  /**
   *
   * SUBSCRIPTIONS - LIST
   * 
   */ 
  
  async getSubscriptions() {
    if(!this.baseAPI) {
      console.error('baseAPI path is not set');
      return null; 
    }

    if(!this.authToken) {
      console.error('authToken is required');
      return null;
    }

    var query = '';

    if(this.searchTerm) {
      query += `searchTerm=${this.searchTerm}&`;
    }
    
    if(this.type) {
      query += `type=${this.type}&`;
    }

    if(this.orderDirection) {
      query += `orderDirection=${this.orderDirection}&`;
    }

    if(this.orderByColumn) {
      query += `orderByColumn=${this.orderByColumn}&`;
    }

    if(this.currentPage) {
      query += `page=${this.currentPage}&`;
    }

    if(this.resPerPage) {
      query += `resPerPage=${this.resPerPage}&`;
    }

    this.url = `${this.baseAPI}system/subscriptions/list?${query}`;
    this.method = 'GET';

    return await this.request();
  }

  /**
   *
   * SUBSCRIPTIONS - SHOW
   * 
   */ 
  
  async getSubscription() {
    if(!this.baseAPI) {
      console.error('baseAPI path is not set');
      return null; 
    }

    if(!this.authToken) {
      console.error('authToken is required');
      return null;
    }

    if(!this.subscriptionUuid) {
      console.error('subscriptionUuid is required');
      return null;
    }

    this.url = `${this.baseAPI}system/subscriptions/show/${this.subscriptionUuid}`;
    this.method = 'GET';

    return await this.request();
  }

  /**
   *
   * SUBSCRIPTIONS - SUBSCRIBE
   * 
   */ 
  
  async assignSubscription() {
    if(!this.baseAPI) {
      console.error('baseAPI path is not set');
      return null; 
    }

    if(!this.authToken) {
      console.error('authToken is required');
      return null;
    }

    if(!this.subscriptionUuid) {
      console.error('subscriptionUuid is required');
      return null;
    }

    this.url = `${this.baseAPI}system/subscriptions/subscribe/${this.subscriptionUuid}`;
    this.method = 'POST';

    return await this.request();
  }
  


  /*
   *
   * Use this as a tempalte to expand the library when needed
   * 
   */ 
  
  async templateFunction() {}
}
