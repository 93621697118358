import { useTranslation } from "react-i18next";
import { Divider, Header, Table, TableHeaderCell } from "semantic-ui-react";
import ProductVendorPriceRow from "./vendors_row";

const ProductsVendorsPrices = (props:any) => {
  const {t, i18n} = useTranslation();

  const _renderVendors = () => {
    if(!props.vendors) return null;
    
    var render:any = [];

    props.vendors.map((vendor:any, key:number) => {
      render.push(
        <ProductVendorPriceRow vendor={vendor} />
      )
    })

    return <>{render}</>
  }

  return <>
    <Divider hidden />

    <Header as='h2' content='Vendors Prices' />

    <Table color='violet'>
      <Table.Header>
        <TableHeaderCell>{t('g.id')}</TableHeaderCell>
        <TableHeaderCell>{t('g.name')}</TableHeaderCell>
        <TableHeaderCell>{t('s.serviceCities.serviceCity')}</TableHeaderCell>
        <TableHeaderCell textAlign="right">{t('s.orders.price')}</TableHeaderCell>
        <TableHeaderCell textAlign="right">{t('s.orders.pit_full')}</TableHeaderCell>
        <TableHeaderCell textAlign="right">{t('g.control')}</TableHeaderCell>
      </Table.Header>
      <Table.Body>
        {_renderVendors()}
      </Table.Body>
    </Table>
  </>
}

export default ProductsVendorsPrices;