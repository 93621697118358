import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { 
  Button,
  Header,
  Input,
  Table,
  Dropdown,
  Divider,
  Modal,
  Message,
  Icon
} from "semantic-ui-react";
import { config } from "../config";
import ReeLaAPI from "../util/reela_lib";
import ProductPricesRow from "./prices_row";


const ProductPrices = (props:any) => {
  const {t, i18n} = useTranslation();

  const [isLoadingServiceCities, setIsLoadingServiceCities] = useState<boolean>(false);
  const [serviceCities, setServiceCities] = useState<any>(null);

  const [isProcessing, setIsProcessing] = useState<boolean>(false);
  const [selectedServiceCity, setSelectedServiceCity] = useState<any>(null);
  const [tierMaxQuantiy, setTierMaxQuantity] = useState<number>(0);
  const [tierPrice, setTierPrice] = useState<number|string>(0.00);
  const [tierPriceIncTax, setTierPriceIncTax] = useState<number|string>(0.00);

  const [prices, setPrices] = useState<any>(props.prices||null);

  const [modal, setModal] = useState<any>();

  useEffect(() => {
    getServiceCities();
  }, []);

  const getServiceCities = async () => {
    if(isLoadingServiceCities) return null;

    setIsLoadingServiceCities(true);

    var reelaApi:any = new ReeLaAPI;
    reelaApi.baseAPI = localStorage.getItem('domain_target');
    reelaApi.authToken = localStorage.getItem('auth_token');
    
    var res:any = await reelaApi.getServiceCities();

    setIsLoadingServiceCities(false);

    if(process.env.NODE_MODE === 'development') console.log('getServiceCities response:', res);

    if(!res || res.status !== 'success') {
      setModal({
        visible: true,
        title: t("g.failedToProcessRequest"),
        message: reelaApi.getErrorsString(),
        actions: [{ content: t("g.ok") }]
      });

      return null;
    } 

    var organizedServiceCities:any = [];

    res.data.forEach((sc:any, key:number) => {
      var local = sc.local;

      if(local && typeof local === 'string') {
        try { local = JSON.parse(local) }
        catch(e) {
          if(process.env.NODE_MODE === 'development') {
            console.error('Failed to parse service city local', sc, e);
          }

          return null;
        }
      }

      organizedServiceCities.push({
        key: sc.id,
        text: `${local[i18n.language||'en'].city}, ${local[i18n.language||'en'].state}, ${local[i18n.language||'en'].country}`,
        value: sc.id
      });
    });

    setServiceCities(organizedServiceCities);
    return null;
  }

  const addProductPrice = async () => {
    // validate the input before submitting
    // >

    if(isProcessing) return null;
    setIsProcessing(true);

    // [ ] Process adding
    var reelaApi:any = new ReeLaAPI;
    reelaApi.baseAPI = localStorage.getItem('domain_target');
    reelaApi.authToken = localStorage.getItem('auth_token');
    reelaApi.productId = props.productId;
    reelaApi.serviceCityId = selectedServiceCity;
    reelaApi.tierPrice = tierPrice;
    reelaApi.tierMaxQuantity = tierMaxQuantiy;

    var res:any = await reelaApi.createProductPrice();
    
    setIsProcessing(false);

    if(process.env.NODE_MODE === 'development') console.log('createProductPrice response:', res);

    if(!res || res.status !== 'success') {
      setModal({
        visible: true,
        title: t("g.failedToProcessRequest"),
        message: reelaApi.getErrorsString(),
        actions: [{ content: t("g.ok") }]
      });

      return null;
    }

    /**
     * FUTURE ENHANCEMENTS ---
     * 
     * Update the prices list to include the new entry
     * this method is using a workarround to adding showing
     * the new selected service city in the prices table.

     * the data received from the server has the city, state, and country
     * in the received object itself. when entering a new record
     * the user is selecting the service city ID only from the dropdown
     * currently we are looping the service cities list we are
     * using on the dropdown, and spliting the text value by ','
     * and showing the values this work 100% correctly with no issues.

     * However, a better approche would be to force the dropdown
     * to use the whole service city object record as a value
     * value: sc and use sc.id when needed the rest can be used
     * as normal.
     */
    

    var updatedPrices = props.prices || [];
    var serviceCityLabels = {};

    if(serviceCities.length > 0) {
      serviceCities.forEach((sc:any, key:number) => {
        if(sc.key === selectedServiceCity) {
          serviceCityLabels = {
            city: sc.text?.split(',')[0],
            state: sc.text?.split(',')[1],
            country: sc.text?.split(',')[2]
          }
        }
      })
    }

    updatedPrices.push({
      id: res.data.id,
      product_id: props.productId,
      service_city_id: selectedServiceCity,
      tier_max_quantity: tierMaxQuantiy,
      tier_price: tierPrice,
      ...serviceCityLabels
    });

    setPrices([...updatedPrices]);

    // clear values
    setTierMaxQuantity(0);
    setTierPrice(0);
    setSelectedServiceCity(null);

    return null;
  }

  const _renderPrices = () => {
    var render:any = [];

    if(!props.prices || props.prices.length < 1) {
      render.push(<Table.Row>
        <Table.Cell>
          {t('g.noResults')}
        </Table.Cell>
      </Table.Row>)
    }
    else {
      props.prices.map((price:any, key:number) => {
        // edit row
        render.push(
          <ProductPricesRow 
            price={price}
          />
        );
      });
    }

    return <Table.Body>
      {render}
    </Table.Body>
  }

  const _renderNewPriceForm = () => {
    if(!props.productId) return null;

    // render the input row
    if(!props.management) return null;
    
    return  <Table.Row>
      <Table.Cell></Table.Cell>
      <Table.Cell>
        <Dropdown
          placeholder={t('s.serviceCities.selectServiceCity') || 'Select Service City'}
          fluid
          selection
          options={serviceCities}
          value={selectedServiceCity}
          onChange={(e, { name, value }) => setSelectedServiceCity(value)}
        />
      </Table.Cell>
      <Table.Cell>
        <Input
          required
          placeholder='0' 
          value={tierMaxQuantiy.toString()||''}
          onChange={(e, {value}) => {
            // add validation to accept numbers and . only
            setTierMaxQuantity(Number(value))
          }}
        />
      </Table.Cell>
      <Table.Cell textAlign="right">
        <Input
          required
          placeholder='0.00'
          label={t(`c.${localStorage.getItem('currency-key')}`)}
          labelPosition="right"
          value={tierPrice.toString()||''}
          onChange={(e, {value}) => {
            // add validation to accept numbers and . only
            setTierPrice(value);
            setTierPriceIncTax((Number(value) + (Number(value) * 0.15)));
          }}
        />
      </Table.Cell>
      <Table.Cell textAlign="right">
        <Input
          required
          placeholder='0.00'
          label={t(`c.${localStorage.getItem('currency-key')}`)}
          labelPosition="right"
          value={tierPriceIncTax.toString()||''}
          onChange={(e, {value}) => {
            // add validation to accept numbers and . only
            setTierPrice((Number(value) / (1.15)).toFixed(2));
            setTierPriceIncTax(value);
          }}
        />
      </Table.Cell>
      <Table.Cell textAlign="right">
        <Button
          icon='plus'
          primary
          content={t('g.add')}
          onClick={() => addProductPrice()}
        />
      </Table.Cell>
    </Table.Row>
  }

  return <>
    <Modal
      size='mini'
      onClose={() => setModal(null)}
      open={(modal && modal.visible) ? true : false}
      header={modal ? modal.title : null}
      content={modal ? modal.message : null}
      actions={modal ? modal.actions : ['Close']}
    />
    
    <Divider hidden />

    <Header as='h2' content='Prices' />

    {props.directPricingWarning && <>
      <Message warning>
        <Message.Header>Pricing Module: Direct</Message.Header>
        <p>
          Pricing module is set to "Direct". The system will 
          ignore any added pricing to this product and use 
          the vendors prices instead.
        </p>
      </Message> 
    </> }
    
    <Table color='violet' striped>
      <Table.Header>
        <Table.HeaderCell>{t('g.id')}</Table.HeaderCell>
        <Table.HeaderCell>{t('s.serviceCities.serviceCity')}</Table.HeaderCell>
        <Table.HeaderCell>{t('s.products.tierQuantity')}</Table.HeaderCell>
        <Table.HeaderCell textAlign="right">{t('s.products.tierPrice')}</Table.HeaderCell>
        <Table.HeaderCell textAlign="right">{t('s.products.tpt_full')}</Table.HeaderCell>
        <Table.HeaderCell textAlign="right">{t('g.control')}</Table.HeaderCell>
      </Table.Header>
      {_renderPrices()}
      {_renderNewPriceForm()}
    </Table>
  </>
}

export default ProductPrices;