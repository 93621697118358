import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { Button, Divider, Grid, Menu, Message } from 'semantic-ui-react';

import Logo from '../assets/logo-horizontal-colored.svg';
import { config } from '../config';

import LanguageSelection from './language';
import SideMenu from './side_menu';

export default function SiteHeader(props:any) {
  const {t, i18n} = useTranslation();
  const navigate = useNavigate();

  const [toggleMenu, setToggleMenu] = useState<boolean>(false);

  return <>
    <Grid stackable>
      <Grid.Row>
        <Grid.Column width={14}>
          {props.hideLogo ? null : <img src={Logo} height={40} />}
        </Grid.Column>
        <Grid.Column width={2}>
          <LanguageSelection/>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row width={16} only='mobile tablet'>
        <Grid.Column>
          <Button
            fluid
            secondary
            content={t('g.menu')}
            onClick={() => setToggleMenu(!toggleMenu)}
          />
          {toggleMenu && <div>
            <Divider hidden />
            <SideMenu />
          </div>}
        </Grid.Column>
      </Grid.Row>
      {(process.env.REACT_APP_MODE === "development") && <Grid.Row width={16}>
        <Grid.Column>
          <Message warning>
            <Message.Header>MODE: Development</Message.Header>
            <p>
              You are in development mode.
            </p>
          </Message>
        </Grid.Column>
      </Grid.Row>}
    </Grid>
  </>
}