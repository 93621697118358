import { useTranslation } from 'react-i18next';
import { Button, Divider, Grid, Header, Image, Segment } from 'semantic-ui-react';
import InternalPage from './framework/internal_page';

import { config } from './config';

import PortalHomeImage from './assets/portal-home-header-image.png';
import { useNavigate } from 'react-router';

export default function Default() {
  const {t, i18n} = useTranslation();
  const navigate = useNavigate();
  
  return <>
    <InternalPage>
      <Grid stackable columns={2}>
        <Grid.Row>
          <Grid.Column width={16}>
            <Header
              as='h1'
              content={`${t('g.welcomeTo')} ${t('g.appName')}`}
              subheader={t('g.useSideMenuToNavigate')}
            />
          </Grid.Column>
        </Grid.Row>
      </Grid>
      
    </InternalPage>
  </>
}