import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import { Checkbox, Divider, Grid, Input, Label, Modal, Segment, Table } from "semantic-ui-react";
import { config } from "../config";
import ReeLaAPI from "../util/reela_lib";

import moment from 'moment';


const ProductsListingRow = (props:any) => {
  const navigate = useNavigate();
  const {t, i18n} = useTranslation();

  const [productPrice, setProductPrice] = useState<number|string|null>(props.product.vendor_price||null);
  
  const [productPriceIncTax, setproductPriceIncTax] = useState<number|string>(
    ((Number(props.product.vendor_price) + (Number(props.product.vendor_price) * 0.15))) || 0);

  const [availability, setAvailability] = useState<boolean>((props.product.vendor_price) ? true : false);

  const [isProcessing, setIsProcessing] = useState<boolean>(false);
  const [modal, setModal] = useState<any>(null);

  const processVendorPrice = async () => {
    if(isProcessing) return false;
  
    if(!productPrice || productPrice < 0.01) {
      setModal({
        visible: true,
        title: t("g.failedToProcessRequest"),
        message: 'Product price cannot bee under 0.01',
        actions: [{ content: t('g.ok') }]
      });

      return null;
    }

    setIsProcessing(true);

    // basedo on current availability status
    var reelaApi:any = new ReeLaAPI;
    reelaApi.baseAPI = localStorage.getItem('domain_target');
    reelaApi.authToken = localStorage.getItem('auth_token');
    reelaApi.price = productPrice;
    reelaApi.productId = props.product.id;
    reelaApi.vendorId = props.vendorId;

    if(availability)
      var res:any = await reelaApi.removeVendorProduct();
    else
      var res:any = await reelaApi.setVendorProduct();

    setIsProcessing(false);

    if(!res || res.status !== 'success') {
      // set modal fail
      setModal({
        visible: true,
        title: t("g.failedToProcessRequest"),
        message: reelaApi.getErrorsString(),
        actions: [{ content: t('g.ok') }]
      });
    }

    else {
      if(availability) setProductPrice(null)
      setAvailability(!availability);
    }
  }

  var productName:any = props.product.name_local;

  if(typeof productName === 'string') {
    try {
      productName = JSON.parse(productName);
    }
    catch(e) {
      if(process.env.NODE_MODE === 'development') console.warn(e);
      productName = '';
    }
  }

  if(typeof productName === 'object') {
    productName = <div>
      {productName.en && <div>{productName.ar}</div>}
      {productName.ar && <div>{productName.ar}</div>}
    </div>
  }
  
  var screenLayout = props.screenLayout || 'table';

  if(screenLayout === 'table') {
    return <>
      <Modal
        size='mini'
        onClose={() => setModal(null)}
        open={(modal && modal.visible) ? true : false}
        header={modal ? modal.title : null}
        content={modal ? modal.message : null}
        actions={modal ? modal.actions : ['Close']}
      />
      
      <Table.Row warning={!props.vendorId && props.product.is_available === 'n' ? true : false}>
        <Table.Cell onClick={props.onClick}>
          {props.product.id}
        </Table.Cell>
        <Table.Cell onClick={props.onClick}>
          {props.product.sku}
        </Table.Cell>
        <Table.Cell onClick={props.onClick}>
          {props.product.barcode}
        </Table.Cell>
        <Table.Cell onClick={props.onClick}>
          {productName}
        </Table.Cell>
        {!props.vendorId && <>
          <Table.Cell onClick={props.onClick}>
            {props.product.listing_order}
          </Table.Cell>
          <Table.Cell onClick={props.onClick}>
            {moment(props.product.created_at).format(config.momentDateFormat)}
          </Table.Cell>
        </> }
        {props.vendorId && <>
        <Table.Cell width={1}>
          <Input
            label={{ basic: true, content: t(`c.${localStorage.getItem('currency-key')}`) }}
            labelPosition='right'
            placeholder='00.00'
            value={productPrice || ''}
            onChange={(e, {value}) => {
              setproductPriceIncTax((Number(value) + (Number(value) * 0.15)));
              setProductPrice(value);
            }}
          />
        </Table.Cell>
        <Table.Cell width={1}>
          <Input
            label={{ basic: true, content: t(`c.${localStorage.getItem('currency-key')}`) }}
            labelPosition='right'
            placeholder='00.00'
            value={productPriceIncTax || ''}
            onChange={(e, {value}) => {
              setproductPriceIncTax(value);
              setProductPrice((Number(value) / (1.15)).toFixed(2));
            }}
          />
        </Table.Cell>
        <Table.Cell textAlign="center" width={1}>
          <Checkbox
            toggle
            checked={availability}
            onChange={(e, data) => {
              // process and update status
              processVendorPrice();
            }}
          />
        </Table.Cell>
        </> }
      </Table.Row>
    </>
  }

  else {
    return <>
      <Modal
        size='mini'
        onClose={() => setModal(null)}
        open={(modal && modal.visible) ? true : false}
        header={modal ? modal.title : null}
        content={modal ? modal.message : null}
        actions={modal ? modal.actions : ['Close']}
      />

      <Divider hidden />
      <Segment color={!props.vendorId && props.product.is_available === 'n' ? "yellow" : undefined}>
        <Grid>
          <Grid.Row columns={2} onClick={props.onClick}>
            <Grid.Column>
              <div style={{fontWeight: 'bold'}}>
                #{props.product.id} - SKU: {props.product.sku}
              </div>
              <div>{productName}</div>
            </Grid.Column>
            <Grid.Column>
              <Grid.Row>
                {t('g.createdAt')} : {moment(props.product.created_at).format(config.momentDateFormat)}
              </Grid.Row>
              {!props.vendorId && <>
                <Grid.Row>
                  {t('g.availability')} <Label size='mini' color={props.product.is_available === 'n' ? 'yellow' : 'green'}>
                    {props.product.is_available === 'n' ? t('g.no') : t('g.yes')}
                  </Label>
                </Grid.Row>
                 <Grid.Row>
                 {t('s.products.listingOrder')} : {props.product.listing_order}
               </Grid.Row>
              </> }
            </Grid.Column>
          </Grid.Row>
        </Grid>
        {props.vendorId && <Grid columns={2}>
          <Grid.Row width='equal'>
            <Grid.Column textAlign="center" verticalAlign="middle">
              <Input
                fluid
                label={{ basic: true, content: t(`c.${localStorage.getItem('currency-key')}`) }}
                labelPosition='right'
                placeholder='00.00'
                value={productPrice || ''}
                onChange={(e, {value}) => {
                  setProductPrice(value)
                }}
              />
            </Grid.Column>
            <Grid.Column textAlign="right" verticalAlign="middle">
              <Checkbox
                toggle
                checked={availability}
                onChange={(e, data) => {
                  // process and update status
                  processVendorPrice();
                }}
              />
            </Grid.Column>
          </Grid.Row>
        </Grid> }
      </Segment>
    </>
  }
}

export default ProductsListingRow;