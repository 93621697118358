// import UI componented
import { useTranslation } from 'react-i18next';
import { Loader, Grid } from 'semantic-ui-react';

import SiteHeader from './site_header';

export default function BlankPage(props:any) {
  const {t, i18n} = useTranslation();

  return (
    <div style={{display: 'flex', justifyContent: 'center'}}>
      <Grid padded stackable container>
        <Grid.Row>
          <Grid.Column width={16}>
            <SiteHeader />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row colSpan='2'>
          <Grid.Column width={16} style={{paddingBottom: '50px'}}>
            {props.loading 
              ? <Loader active content={t('g.loading')} /> 
              : props.children
            }
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </div>
  );
}

